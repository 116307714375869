import Vue from 'vue';
import Vuex from 'vuex';

import auth from '@/stores/auth';
import system from '@/stores/system';
import toasts from '@/stores/toasts';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    system,
    toasts,
  },
});
