<template>
  <mui-data-element
    :title="`${numberForHumans} anrufen`"
    :label="label"
    :icon="icon"
    :placeholder="!number"
    :link="numberAsLink"
    v-on="$listeners"
    v-if="!inDropdown"
  >
    {{ numberForHumans || placeholder }}
  </mui-data-element>

  <mui-button
    :title="`${numberForHumans} anrufen`"
    :icon="icon"
    :link="numberAsLink"
    v-else
  >
    <mui-badge
      size="small"
      :text="label"
      class="mr-4"
      v-if="label && !placeholder"
    />

    {{ numberForHumans || placeholder }}
  </mui-button>
</template>

<script>
import phoneutils from '@/helpers/phone';

export default {
  props: {
    number: {
      type: String,
      default: null,
    },
    country: {
      type: String,
      default: 'DE',
    },
    icon: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: null,
    },
    inDropdown: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    parsedPhone() {
      return phoneutils.parse(this.number);
    },

    numberAsLink() {
      if (!this.parsedPhone) return null;
      return this.parsedPhone.getURI();
    },

    numberForHumans() {
      if (!this.parsedPhone) return null;
      return this.parsedPhone.formatNational();
    },

    areaCode() {
      switch (this.country.toUpperCase()) {
        case 'DE': return '+49';
        default: return null;
      }
    },
  },
};
</script>
