const defaults = {
  app: {
    title: 'Minerva',
    notification_time: 3000, // milliseconds
    calendar_from: '08:00',
    calendar_until: '19:00',
  },

  api: {
    url: '',
    path: '/api',
  },

  routes: {
    NOT_AUTHORIZED: '/login',
    NOT_ALLOWED: '/',
  },

  storage: {
    ACCESS_TOKEN: 'minerva-access',
    VERSION: 'minerva-version',
    VIEW_PREFIX: 'minerva-view',

    ACCESS_EXPIRY_TRESHOLD: 10, // minutes
    VIEW_EXPIRES_AFTER: 10, // minutes
  },

  messages: {
    DEFAULT_ERROR: 'Etwas ist schief gelaufen. Bitte versuche es erneut.',

    LOGIN_ERROR: 'Deine Anmeldedaten sind falsch. Bitte versuche es erneut.',
    LOGIN_RESET: 'Dein Benutzeraccount wurde zurückgesetzt. Lass’ dir ein neues Passwort zuweisen.',

    NEW_VERSION: 'Es ist eine neue Version von Minerva verfügbar. Bitte aktualisieren, um neue Funktionen zu aktivieren.',
  },

  permissions: {
    tasks: {
      index: 'Alle $$ ansehen',
      show: '$$-Details ansehen',
      create: '$$ neu anlegen',
      update: '$$ bearbeiten',
      delete: '$$ löschen',
      export: '$$ exportieren',
      profile: '$$-Profil ansehen und bearbeiten',
    },

    groups: {
      login: { area: 'Allgemein', title: 'Einloggen' },
      activities: { area: 'Allgemein', title: 'Aktivitäten' },
      views: { area: 'Allgemein', title: 'Ansichten' },
      settings: { area: 'Allgemein', title: 'Einstellungen' },
      system: { area: 'Allgemein', title: 'Systemeinstellungen' },

      customers: { area: 'Resourcen', title: 'Kunden' },
      employees: { area: 'Resourcen', title: 'Mitarbeiter' },
      contacts: { area: 'Resourcen', title: 'Kontakte' },
      horses: { area: 'Resourcen', title: 'Pferde' },
      places: { area: 'Resourcen', title: 'Räume' },
      materials: { area: 'Resourcen', title: 'Materialien' },

      events: { area: 'Terminvergabe', title: 'Termine' },
      reservations: { area: 'Terminvergabe', title: 'Wartelisten-Einträge' },
      documentations: { area: 'Terminvergabe', title: 'Dokumentation' },
      actions: { area: 'Terminvergabe', title: 'Maßnahmen für Dokumentation' },

      agreements: { area: 'Abrechnung', title: 'Vereinbarungen' },
      billings: { area: 'Abrechnung', title: 'Abrechnungen' },
      invoices: { area: 'Abrechnung', title: 'Rechnungen' },
      prescriptions: { area: 'Abrechnung', title: 'Verordnungen' },
      contracts: { area: 'Abrechnung', title: 'Verträge' },
      businesses: { area: 'Abrechnung', title: 'Firmenprofile' },
      services: { area: 'Abrechnung', title: 'Leistungen' },

      findings: { area: 'Sonstiges', title: 'Befunde' },

      reports: { area: 'Analyse', title: 'Berichte' },
      statistics: { area: 'Analyse', title: 'Statistiken' },

      users: { area: 'Sicherheit', title: 'Benutzer' },
    },
  },
};

const config = {
  ...defaults,
  ...window.MINERVA,
};
config.api.endpoint = `${config.api.url}/${config.api.path}`;

export default config;
