<template>
  <mui-button-group>
    <mui-partial-back-button :title="parentTitle" :route="parentRoute" />

    <!-- <mui-button icon="chevron-right" theme="static" disabled /> -->

    <mui-button :icon="icon" theme="ghost-highlight-static">
      <slot>
        {{ title }}
      </slot>
    </mui-button>
  </mui-button-group>
</template>

<script>
export default {
  props: {
    parentTitle: {
      type: String,
      default: null,
    },

    parentRoute: {
      type: String,
      default: null,
    },

    icon: {
      type: String,
      default: null,
    },

    title: {
      type: String,
      default: null,
    },
  },
};
</script>
