<template>
  <mui-tile
    icon="file-contract"
    ref="tile"
    @click="$emit('click')"
    :subline="computedSubline"
  >
    <strong slot="title">
      {{ agreement.service.title }}
      <template v-if="agreement.business">
        / {{ agreement.business.code }}
      </template>
    </strong>

    <div class="pr-4 pt-8 pl-2" slot="actions">
      <mui-strings-agreement-status :agreement="agreement" :with-text="false" />
    </div>
  </mui-tile>
</template>

<script>
export default {
  props: {
    agreement: {
      type: Object,
      default: null,
    },

    subline: {
      type: String,
      default: null,
    },
  },

  computed: {
    computedSubline() {
      if (this.subline) {
        return this.subline;
      }

      return this.agreement.uid ? `Nr. ${this.agreement.uid}` : 'Entwurf';
    },
  },
};
</script>
