<template>
  <mui-tile
    expandable
    :subline="subline"
    @open="loadRemoteData"
    ref="tile"
    :icon="icon"
  >
    <mui-icon slot="icon" :type="icon" :class="`color-${iconColor}`" />

    <strong slot="title">
      {{ title }}
    </strong>

    <mui-loader v-if="isLoading" />
    <template v-else>
      <mui-text multiline class="px-8" v-if="resource">
        <strong>{{ resource.headline }}</strong><br>
        {{ resource.description }}<br>
        bei {{ resource.employees.map((employee) => employee.firstname + ' ' + employee.name).join(', ') }}
      </mui-text>
      <hr>
      <mui-button-group direction="vertical">
        <template v-if="withDocumentation && resource">
          <mui-button
            icon="check"
            @click.stop="$refs.eventDocumentation.dialog.toggle()"
          >
            Dokumentieren
          </mui-button>
          <mui-dialog-event-documentation
            ref="eventDocumentation"
            :event="resource"
            :key="resource.id"
            @resource-save="$emit('event-documented', resource)"
          />
        </template>

        <mui-button
          icon="calendar"
          :link="eventLink"
        >
          Termin ansehen
        </mui-button>
      </mui-button-group>
    </template>
  </mui-tile>
</template>

<script>
import {
  get,
} from '@/utils/api';

import EventsDocumentation from '@/views/Dialogs/EventsDocumentation.vue';

import datefns from '@/helpers/datefns';
import times from '@/helpers/times';

export default {
  components: {
    'mui-dialog-event-documentation': EventsDocumentation,
  },

  props: {
    event: {
      type: Object,
      default: null,
    },

    icon: {
      type: String,
      default: 'calendar',
    },

    withDocumentation: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      response: null,
      resource: null,
      isLoading: false,
    };
  },

  computed: {
    endpoint() {
      return `events/${this.event.id}`;
    },

    isWholeDay() {
      return (!this.event.start_time || !this.event.end_time);
    },

    title() {
      return this.formattedDate;
    },

    subline() {
      if (this.isWholeDay) {
        return 'Ganztägig';
      }

      return this.formattedTime;
    },

    formattedDate() {
      return datefns.toLongString(this.event.start_date);
    },

    formattedTime() {
      const start = times.toString(times.toMinutes(this.event.start_time));
      const duration = times.toDifference(this.event.start_time, this.event.end_time);

      let durationString = `${duration} Minuten`;

      if (duration > 120) {
        durationString = `${times.toString(duration)} Stunden`;
      }

      return `${start} Uhr · ${durationString}`;
    },

    eventLink() {
      return {
        name: 'calendar',
        params: {
          date: datefns.toSqlDate(this.event.start_date),
          eventId: this.event.id,
        },
      };
    },

    iconColor() {
      if (this.event && this.event.color) {
        return this.event.color;
      }

      if (this.resource && this.resource.color) {
        return this.resource.color;
      }

      return 'blue-base';
    },
  },

  methods: {
    loadRemoteData() {
      if (!this.resource) {
        this.loadData();
      }
    },

    async loadData() {
      let response;

      if (this.isLoading) return;

      this.isLoading = true;
      try {
        response = await get(this.endpoint, {
          include: 'employees',
          append: 'headline,description,color',
        });
        this.resource = response.data.data;
      } catch (e) {
        response = e.response;
      }

      this.response = response;
      this.isLoading = false;

      this.$nextTick(() => {
        this.$refs.tile.resetContentHeight();
        this.$refs.tile.setContentHeight();
      });
    },
  },
};
</script>
