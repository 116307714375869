<template>
  <mui-data-element
    :icon="icon"
    class="mui-data-element-person"
    @click="$emit('click')"
  >
    <mui-text multiline>
      <mui-headline size="smallest" class="mb-1">
        Nr. {{ person.id }}
      </mui-headline>
      <strong>
        <mui-strings-name address :emphasize="false" :person="person" />
      </strong>
    </mui-text>
  </mui-data-element>
</template>

<script>
export default {
  props: {
    person: {
      type: Object,
      default: null,
    },

    icon: {
      type: String,
      default: 'user',
    },
  },
};
</script>

<style>
.mui-data-element-person + .mui-data-element-person {
  margin-top: .25rem;
}

.mui-data-element-person strong {
  font-weight: 600;
}
</style>
