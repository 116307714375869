<template>
  <mui-loader v-if="isLoading" />
  <mui-field-select
    v-else
    :options="collection"
    :required="required"
    :disabled="disabled"
    labelled-by="fullname"
    tracked-by="id"
    described-by="initials"
    placeholder="Mitarbeiter auswählen"
    searchable
    :search-through="['id', 'name', 'firstname', 'initials']"
    :multiple="multiple"
    :taggable="multiple"
    v-bind="$props"
    v-on="$listeners"
    v-model="$attrs.value"
  >
    <template slot="selection" slot-scope="{ selection, remove }" v-if="multiple">
      <span class="mui-select-selection">
        <mui-tag
          v-for="person in selection"
          :key="person.id"
          removeable
          @remove="remove(person)"
        >
          <mui-strings-name
            :person="person"
            :address="true"
            :emphasize="false"
          />
          ({{ person.initials }})
        </mui-tag>
      </span>
    </template>
  </mui-field-select>
</template>

<script>
import IsCollection from '@/mixins/IsCollection';
import Field from '@/mixins/Field';

export default {
  mixins: [IsCollection, Field],

  props: {
    multiple: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      endpoint: '/employees',

      queryParameters: {
        append: 'fullname,initials',
        fields: {
          employees: 'id,name,firstname',
        },
      },
    };
  },

  mounted() {
    this.initCollection();
  },
};
</script>
