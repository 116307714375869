import Vue from 'vue';

// Usual components
import AppTopbar from '@/components/AppTopbar.vue';
import AppSidebar from '@/components/AppSidebar.vue';
import AppSearch from '@/components/AppSearch.vue';

import EmptyState from '@/components/EmptyState.vue';
import ResourceAvatar from '@/components/ResourceAvatar.vue';
import CalendarEvent from '@/components/CalendarEvent.vue';
import ProfileEvent from '@/components/ProfileEvent.vue';

import TilePerson from '@/components/TilePerson.vue';
import TileInvoice from '@/components/TileInvoice.vue';
import TileAgreement from '@/components/TileAgreement.vue';
import TileEvent from '@/components/TileEvent.vue';
import TileEventDocumentation from '@/components/TileEventDocumentation.vue';

import WidgetWelcome from '@/components/Widgets/Welcome.vue';
import WidgetMemo from '@/components/Widgets/Memo.vue';
import WidgetCalendar from '@/components/Widgets/Calendar.vue';
import WidgetBirthdays from '@/components/Widgets/Birthdays.vue';
import WidgetInvoices from '@/components/Widgets/Invoices.vue';
import WidgetAgreements from '@/components/Widgets/Agreements.vue';
import WidgetEvents from '@/components/Widgets/Events.vue';

// Partials
import PartialBackButton from '@/components/Partials/BackButton.vue';
import PartialBreadcrumb from '@/components/Partials/Breadcrumb.vue';
import PartialSaveView from '@/components/Partials/SaveView.vue';
import PartialSelectView from '@/components/Partials/SelectView.vue';

// General dialogs
import DialogResourceDeleteConfirmation from '@/components/Dialogs/ResourceDeleteConfirmation.vue';
import DialogResourceForceDeleteConfirmation from '@/components/Dialogs/ResourceForceDeleteConfirmation.vue';

// Forms
import FormEvent from '@/components/Forms/Event.vue';

import FieldActions from '@/components/Fields/Actions.vue';
import FieldAddress from '@/components/Fields/Address.vue';
import FieldAddresses from '@/components/Fields/Addresses.vue';
import FieldBirthday from '@/components/Fields/Birthday.vue';
import FieldBusinesses from '@/components/Fields/Businesses.vue';
import FieldColors from '@/components/Fields/Colors.vue';
import FieldCustomers from '@/components/Fields/Customers.vue';
import FieldDate from '@/components/Fields/Date.vue';
import FieldEmail from '@/components/Fields/Email.vue';
import FieldEmployees from '@/components/Fields/Employees.vue';
import FieldEvents from '@/components/Fields/Events.vue';
import FieldHorses from '@/components/Fields/Horses.vue';
import FieldMaterials from '@/components/Fields/Materials.vue';
import FieldNumber from '@/components/Fields/Number.vue';
import FieldPassword from '@/components/Fields/Password.vue';
import FieldPhone from '@/components/Fields/Phone.vue';
import FieldPlaces from '@/components/Fields/Places.vue';
import FieldPrice from '@/components/Fields/Price.vue';
import FieldSelect from '@/components/Fields/Select.vue';
import FieldServices from '@/components/Fields/Services.vue';
import FieldTags from '@/components/Fields/Tags.vue';
import FieldText from '@/components/Fields/Text.vue';
import FieldTextarea from '@/components/Fields/Textarea.vue';
import FieldTime from '@/components/Fields/Time.vue';

import DataElementAddress from '@/components/DataElements/Address.vue';
import DataElementBirthday from '@/components/DataElements/Birthday.vue';
import DataElementDate from '@/components/DataElements/Date.vue';
import DataElementEmail from '@/components/DataElements/Email.vue';
import DataElementName from '@/components/DataElements/Name.vue';
import DataElementPassword from '@/components/DataElements/Password.vue';
import DataElementPhone from '@/components/DataElements/Phone.vue';
import DataElementTags from '@/components/DataElements/Tags.vue';
import DataElementText from '@/components/DataElements/Text.vue';
import DataElementPerson from '@/components/DataElements/Person.vue';

import StringsName from '@/components/Strings/Name.vue';
import StringsPhone from '@/components/Strings/Phone.vue';
import StringsAddress from '@/components/Strings/Address.vue';
import StringsPrice from '@/components/Strings/Price.vue';
import StringsAgreementStatus from '@/components/Strings/AgreementStatus.vue';
import StringsInvoiceStatus from '@/components/Strings/InvoiceStatus.vue';

import TemplateProfileEvents from '@/components/Templates/ProfileEvents.vue';

// Ui library
import Ui from '@/../ui/dist/ui.umd.min';

Vue.use(Ui);

Vue.component('mui-app-topbar', AppTopbar);
Vue.component('mui-app-sidebar', AppSidebar);
Vue.component('mui-app-search', AppSearch);

Vue.component('mui-empty-state', EmptyState);
Vue.component('mui-resource-avatar', ResourceAvatar);
Vue.component('mui-calendar-event', CalendarEvent);
Vue.component('mui-profile-event', ProfileEvent);

Vue.component('mui-tile-person', TilePerson);
Vue.component('mui-tile-invoice', TileInvoice);
Vue.component('mui-tile-agreement', TileAgreement);
Vue.component('mui-tile-event', TileEvent);
Vue.component('mui-tile-event-documentation', TileEventDocumentation);

Vue.component('mui-widget-welcome', WidgetWelcome);
Vue.component('mui-widget-memo', WidgetMemo);
Vue.component('mui-widget-calendar', WidgetCalendar);
Vue.component('mui-widget-birthdays', WidgetBirthdays);
Vue.component('mui-widget-invoices', WidgetInvoices);
Vue.component('mui-widget-agreements', WidgetAgreements);
Vue.component('mui-widget-events', WidgetEvents);

Vue.component('mui-partial-breadcrumb', PartialBreadcrumb);
Vue.component('mui-partial-back-button', PartialBackButton);
Vue.component('mui-partial-save-view', PartialSaveView);
Vue.component('mui-partial-select-view', PartialSelectView);

Vue.component('mui-form-event', FormEvent);

Vue.component('mui-dialog-resource-delete-confirmation', DialogResourceDeleteConfirmation);
Vue.component('mui-dialog-resource-force-delete-confirmation', DialogResourceForceDeleteConfirmation);

Vue.component('mui-field-actions', FieldActions);
Vue.component('mui-field-address', FieldAddress);
Vue.component('mui-field-addresses', FieldAddresses);
Vue.component('mui-field-birthday', FieldBirthday);
Vue.component('mui-field-businesses', FieldBusinesses);
Vue.component('mui-field-colors', FieldColors);
Vue.component('mui-field-customers', FieldCustomers);
Vue.component('mui-field-date', FieldDate);
Vue.component('mui-field-email', FieldEmail);
Vue.component('mui-field-employees', FieldEmployees);
Vue.component('mui-field-events', FieldEvents);
Vue.component('mui-field-horses', FieldHorses);
Vue.component('mui-field-materials', FieldMaterials);
Vue.component('mui-field-number', FieldNumber);
Vue.component('mui-field-password', FieldPassword);
Vue.component('mui-field-phone', FieldPhone);
Vue.component('mui-field-places', FieldPlaces);
Vue.component('mui-field-price', FieldPrice);
Vue.component('mui-field-select', FieldSelect);
Vue.component('mui-field-services', FieldServices);
Vue.component('mui-field-tags', FieldTags);
Vue.component('mui-field-text', FieldText);
Vue.component('mui-field-textarea', FieldTextarea);
Vue.component('mui-field-time', FieldTime);

Vue.component('mui-data-element-address', DataElementAddress);
Vue.component('mui-data-element-birthday', DataElementBirthday);
Vue.component('mui-data-element-date', DataElementDate);
Vue.component('mui-data-element-email', DataElementEmail);
Vue.component('mui-data-element-name', DataElementName);
Vue.component('mui-data-element-password', DataElementPassword);
Vue.component('mui-data-element-phone', DataElementPhone);
Vue.component('mui-data-element-tags', DataElementTags);
Vue.component('mui-data-element-text', DataElementText);
Vue.component('mui-data-element-person', DataElementPerson);

Vue.component('mui-strings-name', StringsName);
Vue.component('mui-strings-phone', StringsPhone);
Vue.component('mui-strings-address', StringsAddress);
Vue.component('mui-strings-price', StringsPrice);
Vue.component('mui-strings-agreement-status', StringsAgreementStatus);
Vue.component('mui-strings-invoice-status', StringsInvoiceStatus);

Vue.component('mui-template-profile-events', TemplateProfileEvents);
