<template>
  <mui-loader v-if="isLoading" />
  <mui-field-select
    v-else
    :options="collection"
    labelled-by="title"
    tracked-by="id"
    described-by="code"
    placeholder="Raum auswählen"
    :required="required"
    searchable
    :multiple="multiple"
    v-bind="$props"
    v-on="$listeners"
    v-model="$attrs.value"
  />
</template>

<script>
import IsCollection from '@/mixins/IsCollection';
import Field from '@/mixins/Field';

export default {
  mixins: [IsCollection, Field],

  props: {
    multiple: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      endpoint: '/places',

      queryParameters: {
        fields: {
          services: 'id,title,code',
        },
      },
    };
  },

  mounted() {
    this.initCollection();
  },
};
</script>
