import isPast from 'date-fns/isPast';
import datefns from '@/helpers/datefns';
import times from '@/helpers/times';
import { textpattern } from '@/helpers/repetition';

export default {
  props: {
    event: {
      type: Object,
      default: null,
    },
  },

  computed: {
    isCancelled() {
      if (this.event.status === 'weather') {
        return true;
      }
      if (this.event.status === 'notattended') {
        return true;
      }
      return false;
    },

    isDone() {
      return isPast(
        datefns.toDate(`${this.event.start_date} ${this.event.start_time}`),
      );
    },

    isMyEvent() {
      if (this.hasEmployees) {
        if (this.event.employees.find(
          (employee) => employee.id === this.$store.state.auth.user.is_employee,
        )) {
          return true;
        }
      }

      return false;
    },

    isDocumentable() {
      if (!this.event.service) {
        return false;
      }

      const allowed = ['optional', 'required'];
      return allowed.includes(this.event.service.documentable);
    },

    canBeDocumented() {
      return this.isDone
        && this.isMyEvent
        && this.isDocumentable;
    },

    hasCustomers() {
      return this.event.customers.length;
    },

    hasEmployees() {
      return this.event.employees.length;
    },

    hasHorses() {
      return this.event.horses && this.event.horses.length;
    },

    hasService() {
      return !!this.event.service;
    },

    hasWarning() {
      return !!this.warningStatus;
    },

    hasDetails() {
      return this.event.place
        || this.event.service
        || (this.event.materials && this.event.materials.length);
    },

    warningStatus() {
      if (this.hasCustomers && !this.hasEmployees) {
        return 'no-employee';
      }

      if (this.hasService && !this.hasCustomers) {
        return 'no-customer';
      }

      // if (this.isOverbooked) return 'overbooked-employee';

      return null;
    },

    warningText() {
      switch (this.warningStatus) {
        case 'no-employee':
          return 'Kein Mitarbeiter hinzugefügt.';

        case 'no-customer':
          return 'Kein Kunde hinzugefügt.';

        default:
          return null;
      }
    },

    repetitionText() {
      if (this.event.repetition) {
        return textpattern(this.event.repetition);
      }

      return null;
    },

    isMarked() {
      if (this.event.place) {
        if (this.event.place.extern) {
          return true;
        }
      }

      return false;
    },

    statusIcon() {
      if (this.event.status === 'weather') {
        return 'cloud-rain';
      }
      if (this.event.status === 'notattended') {
        return 'align-slash';
      }
      if (this.event.status === 'attended') {
        return 'check';
      }

      return 'circle';
    },

    statusColor() {
      let { color } = this.event;

      if (Array.isArray(color)) {
        color = color[0];
      }

      color = color.replace('-striped', '');

      if (!color.endsWith('-dark') && !color.endsWith('-light')) {
        color += '-base';
      }

      return `color-${color}`;
    },

    statusText() {
      if (this.event.status === 'weather') {
        return 'Termin wetterbedingt abgesagt';
      }
      if (this.event.status === 'notattended') {
        return 'Termin nicht wahrgenommen';
      }
      if (this.event.status === 'attended') {
        return 'Termin wahrgenommen';
      }

      return null;
    },

    dateOrTimeAsText() {
      if (!this.event.start_time || !this.event.end_time) {
        const start = datefns.toFormat(this.event.start_date);
        const end = datefns.toFormat(this.event.end_date);

        if (start === end) {
          return start;
        }

        return `${start} – ${end}`;
      }

      const start = times.toString(times.toMinutes(this.event.start_time));
      const end = times.toString(times.toMinutes(this.event.end_time));

      return `${start} – ${end} Uhr`;
    },

    datetimeAsText() {
      const startDate = datefns.toFormat(this.event.start_date);
      let startTime;

      if (this.event.start_time) {
        startTime = times.toString(times.toMinutes(this.event.start_time));
        startTime = `, ${startTime} Uhr`;
      }

      return `${startDate} ${startTime}`;
    },

    employeesCode() {
      if (this.hasEmployees) {
        let prefix = this.event.employees[0].initials;

        if (this.event.employees.length > 1 || (this.hasHorses && this.event.horses.length > 1)) {
          prefix += '+';
        }

        return prefix.toUpperCase();
      }

      return null;
    },
  },

};
