import Vue from 'vue';
import Router from 'vue-router';

import config from '@/config';
import routes from './routes';

Vue.use(Router);

const router = new Router({
  mode: 'history',

  routes,

  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    }

    if (savedPosition) {
      return savedPosition;
    }

    return { x: 0, y: 0 };
  },

  linkActiveClass: '-is-open',
  linkExactActiveClass: '-is-active',

});

router.beforeEach((to, from, next) => {
  const store = router.app.$store;

  if (process.env.NODE_ENV === 'production') {
    store.dispatch('system/compareVersion');
  }

  if (to.matched.some((route) => route.meta.outside)) {
    next();
  } else if (!store.getters['auth/isLoggedIn']()) {
    store.dispatch('auth/setRouteAfterLogin', to.fullPath);
    next(config.routes.NOT_AUTHORIZED);
  } else if (!store.getters['auth/isStillValid']()) {
    store.dispatch('auth/setRouteAfterLogin', to.fullPath);
    // store.dispatch('toasts/push', {
    //   title: 'Deine Sitzung ist abgelaufen.',
    //   text: 'Bitte melde dich erneut an.',
    // });
    next('/logout');
  } else if (store.getters['auth/isShortlyExpiring']()) {
    store.dispatch('auth/refresh');
    next();
  } else if (to.matched.some((route) => route.meta.permissions)) {
    if (store.getters['auth/isAllowedTo'](to.meta.permissions)) {
      next();
    } else {
      next(config.routes.NOT_ALLOWED);
    }
  } else {
    next();
  }
});

export default router;
