<template>
  <mui-tile
    ref="tile"
    :class="isCancelled ? '-cancelled-event' : null"
  >
    <div slot="title">
      <strong>
        {{ formattedDate }}
      </strong>
      <strong class="color-grey-dark ml-4">
        {{ formattedTime }}
      </strong>
    </div>

    <div class="p-6" slot="actions">
      {{ event.service.title }}
    </div>

    <mui-grid class="p-6" v-if="hasDocumentation">
      <mui-col width="2/6">
        <mui-headline size="small" class="mb-4">
          Mitarbeiter
        </mui-headline>
        {{ event.employees.map((employee) => employee.firstname + ' ' + employee.name).join(', ') }}

        <template v-if="event.horses.length">
          <mui-headline size="small" class="mt-8 mb-4">
            Pferde
          </mui-headline>

          {{ event.horses.map((horse) => horse.name).join(', ') }}
        </template>
      </mui-col>

      <mui-col width="3/6">
        <mui-headline size="small" class="mb-4">
          Maßnahmen
        </mui-headline>
        <mui-list :items="actions" />
      </mui-col>

      <mui-col width="1/6" align="right" v-if="withRating">
        <mui-headline size="small" class="mb-4">
          Bewertung
        </mui-headline>
        <mui-icon :type="icon" :class="`color-${iconColor}`" style="font-size:1.5em" />
      </mui-col>

      <mui-col width="2/6" v-if="event.documentation.comment" />
      <mui-col width="4/6" v-if="event.documentation.comment">
        <mui-headline size="small" class="mb-4">
          Anmerkungen
        </mui-headline>
        <mui-text multiline>
          {{ event.documentation.comment }}
        </mui-text>
      </mui-col>
    </mui-grid>

    <div v-else-if="isCancelled" class="p-6 -cancelled-message">
      <mui-icon type="exclamation-triangle" class="color-orange-dark mr-2" />
      <span v-if="event.status === 'weather'">wetterbedingt ausgefallen</span>
      <span v-else-if="event.status === 'notattended'">unentschuldigt nicht wahrgenommen</span>
    </div>
  </mui-tile>
</template>

<script>
import datefns from '@/helpers/datefns';
import times from '@/helpers/times';

export default {
  props: {
    event: {
      type: Object,
      default: null,
    },

    withRating: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    isWholeDay() {
      return (!this.event.start_time || !this.event.end_time);
    },

    formattedDate() {
      return datefns.toShortString(this.event.start_date);
    },

    formattedTime() {
      if (this.isWholeDay) {
        return null;
      }

      const start = times.toString(times.toMinutes(this.event.start_time));
      const end = times.toString(times.toMinutes(this.event.end_time));

      return `${start} – ${end} Uhr`;
    },

    hasDocumentation() {
      return !!this.event.documentation;
    },

    isCancelled() {
      return this.event.status === 'weather' || this.event.status === 'notattended';
    },

    actions() {
      if (!this.hasDocumentation) {
        return null;
      }

      return this.event.documentation.actions.map((item) => item.name);
    },

    icon() {
      if (!this.hasDocumentation) {
        return null;
      }

      switch (this.event.documentation.rating) {
        case 'positive':
          return 'far-grin';
        case 'neutral':
          return 'far-meh';
        case 'negative':
          return 'far-frown-open';
        default:
          return null;
      }
    },

    iconColor() {
      if (!this.hasDocumentation) {
        return null;
      }

      switch (this.event.documentation.rating) {
        case 'positive':
          return 'green-dark';
        case 'neutral':
          return 'yellow-dark';
        case 'negative':
          return 'red-light';
        default:
          return null;
      }
    },
  },
};
</script>

<style lang="scss">
.-cancelled-event {
  .mui-tile-titles,
  .mui-tile-actions {
    text-decoration: line-through;
    opacity: .75;
  }
}
</style>
