<template>
  <span class="-formatted-address">
    <template v-if="oneliner">
      <template v-if="address.name">{{ address.name }}, </template>
      <template>{{ address.street }}, </template>
      <template v-if="address.additional">{{ address.additional }}, </template>
      <template>{{ address.postcode }} {{ city }}</template>
    </template>

    <template v-else>
      <span v-if="address.name">{{ address.name }}</span>
      <span>{{ address.street }}</span>
      <span v-if="address.additional">{{ address.additional }}</span>
      <span>{{ address.postcode }} {{ city }}</span>
    </template>
  </span>
</template>

<script>
export default {
  props: {
    address: {
      type: Object,
      default: null,
    }, // { name, street, additional, postcode }

    oneliner: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    city() {
      if (this.address) {
        if (this.address.postcode) {
          const city = this.$store.getters['system/getCity'](this.address.postcode);

          if (city) {
            return city.name;
          }
        }
      }

      return null;
    },
  },
};
</script>

<style>
.-formatted-address span {
  display: block;
  line-height: 1.4;
}
</style>
