<template>
  <mui-dialog size="small" ref="dialog">
    <mui-toolbar slot="header">
      <mui-button-group slot="left">
        <mui-button theme="ghost-highlight-static" icon="archive">
          Termin dokumentieren
        </mui-button>
      </mui-button-group>

      <mui-button-group slot="right">
        <mui-button icon="times" @click="$refs.dialog.close()">
          Schließen
        </mui-button>
      </mui-button-group>
    </mui-toolbar>


    <mui-sheet-inner padded>
      <mui-grid gutter="large">
        <mui-col>
          <mui-event
            :title="datetimeAsText"
            :info="`${event.headline} · ${event.description}`"
            :color="event.color"
            :meta="employeesCode"
          />
        </mui-col>

        <mui-col>
          <mui-input-rating
            label="Bewertung des Termins"
            v-model="resource.rating"
          />
        </mui-col>

        <mui-col>
          <mui-field-actions
            label="Durchgeführte Maßnahmen"
            v-model="resource.actions"
            :required="true"
            :readable="false"
            :edit="true"
            :has-error="hasFieldErrors('actions')"
            :info="getFieldErrors('actions')"
          />
        </mui-col>

        <mui-col>
          <mui-field-textarea
            label="Weitere Anmerkungen"
            placeholder="z.B. Besonderheiten oder Notizen"
            v-model="resource.comment"
            :readable="false"
            :edit="true"
            :has-error="hasFieldErrors('comment')"
            :info="getFieldErrors('comment')"
          />
        </mui-col>
      </mui-grid>
    </mui-sheet-inner>


    <template slot="footer">
      <mui-button
        theme="primary"
        icon="check"
        @click="storeResource"
        :disabled="isLocked"
      >
        Termin dokumentieren
      </mui-button>

      <mui-button icon="sync" theme="static" v-if="isLocked" />
      <mui-button class="ml-auto" @click="$refs.dialog.close()" v-else>
        Abbrechen
      </mui-button>
    </template>
  </mui-dialog>
</template>

<script>
import Dialog from '@/mixins/Dialog';
import DialogCanCreate from '@/mixins/DialogCanCreate';
import EventResource from '@/mixins/Resources/Event';

export default {
  mixins: [Dialog, DialogCanCreate, EventResource],

  props: {
    event: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      endpoint: '/documentations',

      resource: {
        actions: null,
        rating: null,
        comment: null,
      },
    };
  },

  methods: {
    afterResourceSaved() {
      this.$refs.dialog.close();
      this.$emit('resource-save', this.event);
      this.resetDialogData();
    },
  },

  mounted() {
    this.resource.event_id = this.event.id;
  },
};
</script>
