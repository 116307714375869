<template>
  <mui-sheet>
    <mui-toolbar slot="header">
      <mui-button-group slot="left">
        <mui-button icon="calendar" theme="ghost-highlight-static">
          Dokumentieren
          <template v-if="events.length">
            ({{ allEventsCount }})
          </template>
        </mui-button>
      </mui-button-group>
    </mui-toolbar>

    <mui-loader v-if="isLoading" />
    <template v-else>
      <mui-sheet-inner padded v-if="!events.length">
        <mui-flex>
          <mui-icon-colored type="check" color="green" style="flex-shrink:0" />
          <span>Keine Termine zu bearbeiten.</span>
        </mui-flex>
      </mui-sheet-inner>

      <div class="p-2" v-else>
        <mui-tile-event
          v-for="event in events"
          :key="event.id"
          :event="event"
          with-documentation
          icon="circle"
          @event-documented="afterEventDocumented(event)"
        />
        <div class="p-6">
          … und {{ allEventsCount - events.length }} weitere.
        </div>
      </div>
    </template>
  </mui-sheet>
</template>

<script>
import {
  isFuture,
} from 'date-fns';

import { get } from '@/utils/api';
import datefns from '@/helpers/datefns';

export default {
  data() {
    return {
      isLoading: false,
      events: [],

      allEventsCount: 0,

      maxEventsToShow: 5,
    };
  },

  methods: {
    async loadEvents() {
      this.isLoading = true;

      const response = await get('/events', {
        filter: {
          after: '2021-02-01',
          before: datefns.toSqlDate(new Date()),
          isNotDocumented: true,
          hasEmployees: this.$store.state.auth.user.is_employee,
        },
        include: 'horses',
        append: 'color',
        sort: 'start_date,start_time',
      });

      const allEvents = response.data.data;
      const filteredEvents = allEvents.filter((event) => !isFuture(
        datefns.toDate(`${event.start_date} ${event.start_time}`),
      ));
      const shortenedEvents = filteredEvents.slice(0, this.maxEventsToShow);

      this.allEventsCount = allEvents.length;
      this.events = shortenedEvents;

      this.isLoading = false;
    },

    afterEventDocumented() {
      this.loadEvents();
    },
  },

  mounted() {
    if (this.$store.state.auth.user.is_employee) {
      this.loadEvents();
    }
  },
};
</script>
