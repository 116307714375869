export default {
  install(Vue) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$mq = new Vue({

      data() {
        return {
          breakpoint: null,
        };
      },

      methods: {
        setBreakpoint() {
          this.breakpoint = window.getComputedStyle(document.body, ':before').content.replace(/"/g, '');
        },
      },

      created() {
        this.setBreakpoint();
        window.addEventListener('resize', this.setBreakpoint);
      },

      destroyed() {
        window.removeEventListener('resize', this.setBreakpoint);
      },
    });
  },
};
