<template>
  <div class="mui-empty-state">
    <figure class="mui-empty-state-figure">
      <img src="../../img/undraw_no_data_qbuo.svg">
    </figure>

    <div class="mui-empty-state-text color-grey-darker">
      <slot>
        <p>Es ist noch kein {{ title }} vorhanden.</p>

        <mui-button theme="primary" icon="plus" @click="$emit('click-button')">
          {{ title }} anlegen
        </mui-button>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
.mui-empty-state {
  padding: 2em 4em;
  display: flex;
  align-items: center;
}

.mui-empty-state-figure {
  max-width: 22em;
  margin-right: 2em;
}

.mui-empty-state-figure img {
  width: 100%;
}

.mui-empty-state-text p {
  font-size: 1.25em;
}
</style>
