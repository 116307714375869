export default {
  inheritAttrs: false,

  props: {
    label: {
      type: String,
      default: null,
    },

    icon: {
      type: String,
      default: null,
    },

    info: {
      type: String,
      default: null,
    },

    placeholder: {
      type: [String, Object],
      default: '',
    },

    theme: {
      type: String,
      default: null,
    },

    prefix: {
      type: String,
      default: null,
    },

    suffix: {
      type: String,
      default: null,
    },

    hasError: {
      type: Boolean,
      default: false,
    },

    hasWarning: {
      type: Boolean,
      default: false,
    },

    edit: {
      type: Boolean,
      default: false,
    },

    editable: {
      type: Boolean,
      default: true,
    },

    readable: {
      type: Boolean,
      default: true,
    },

    pinnable: {
      type: Boolean,
      default: false,
    },

    pinned: {
      type: Boolean,
      default: false,
    },

    removable: {
      type: Boolean,
      default: false,
    },

    orientation: {
      type: String,
      default: 'horizontal', // vertical
    },

    autofocus: {
      type: Boolean,
      default: false,
    },

    required: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isEditing: this.edit,
    };
  },

  computed: {
    id() {
      // eslint-disable-next-line no-underscore-dangle
      return this._uid;
    },

    inputListeners() {
      const vm = this;

      return {
        ...this.$listeners,

        input(event) {
          vm.$emit('input', event.target.value);
        },
      };
    },

    value() {
      return this.$attrs.value;
    },
  },

  methods: {
    toggleMode() {
      this.isEditing = true;
    },

    onPin() {
      this.$emit('pin');
    },

    onRemove() {
      this.$emit('remove');
    },

    onSave() {
      this.$emit('save');
    },
  },
};
