<template>
  <mui-field
    :label="label"
    :icon="icon"
    :info="info"
    :has-error="hasError"
    :has-warning="hasWarning"
    :id="id"
    :edit="edit"
    :editable="editable"
    :readable="readable"
    :pinnable="pinnable"
    :removable="removable"
    @save="onSave"
    @pin="onPin"
    @remove="onRemove"
  >
    <mui-input-select
      searchable
      multiple
      taggable
      :addable="addable"
      :tracked-by="trackedBy"
      :labelled-by="labelledBy"
      :options="options"
      :required="required"
      :placeholder="placeholder"
      v-model="selectedOption"
    >
      <template slot="selection" slot-scope="{ selection, remove }">
        <span class="mui-select-selection">
          <mui-tag
            v-for="(tag, key) in selection"
            :key="key"
            :text="tag[labelledBy]"
            removeable
            @remove="remove(tag)"
          />
        </span>
      </template>
    </mui-input-select>

    <mui-data-element-tags
      :icon="icon"
      :tags="selectedOption"
      :placeholder="placeholder"
      :labelled-by="labelledBy"
      slot="read"
    />
  </mui-field>
</template>

<script>
import field from '@/mixins/Field';

export default {
  mixins: [field],

  props: {
    options: {
      type: Array,
      default: null,
    },

    placeholder: {
      type: String,
      default: 'Bitte auswählen',
    },

    trackedBy: {
      type: String,
      default: 'value',
    },

    labelledBy: {
      type: String,
      default: 'label',
    },

    addable: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    selectedLabel() {
      if (!this.selectedOption) return null;

      const values = this.selectedOption.map((option) => option[this.labelledBy]);
      return values.map((value) => `#${value}`).join(', ');
    },
  },

  data() {
    return {
      selectedOption: this.$attrs.value,
    };
  },

  watch: {
    selectedOption() {
      this.$emit('input', this.selectedOption);
    },
  },
};
</script>
