import axios from 'axios';
import { addSeconds, formatISO } from 'date-fns';

import config from '@/config';
import storage from '@/utils/storage';
import http from './http';
import { getHeaders } from './http-helper';
import { post } from './api';


const request = axios.create({
  baseURL: `${config.api.endpoint}`,
  headers: getHeaders(),
  withCredentials: true,
});


const validateAuthResponse = (response) => {
  if (response.data && response.data.access_token) {
    const data = {
      access_token: response.data.access_token,
      user: response.data.user,
      expires: formatISO(addSeconds(new Date(), response.data.expires_in)),
    };

    storage.set(config.storage.ACCESS_TOKEN, data);

    http.defaults.headers = getHeaders();
    request.defaults.headers = getHeaders();

    return data;
  }
  return response;
};

const login = (credentials) => request.post('/auth/login', credentials).then(validateAuthResponse);

const refresh = () => post('/auth/refresh').then(validateAuthResponse);

const logout = () => post('/auth/logout');

export default {
  login,
  refresh,
  logout,
};
