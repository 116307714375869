<template>
  <mui-sheet>
    <mui-toolbar slot="header">
      <mui-button-group slot="left">
        <mui-button icon="file-invoice" theme="ghost-highlight-static">
          Vereinbarungen
          <template v-if="agreements.length">
            ({{ allAgreementsCount }})
          </template>
        </mui-button>
      </mui-button-group>

      <mui-button-group slot="right">
        <mui-button icon="arrow-right" @click="goToAgreements" />
      </mui-button-group>
    </mui-toolbar>

    <mui-loader v-if="isLoading" />
    <template v-else>
      <mui-sheet-inner padded v-if="!agreements.length">
        <mui-flex>
          <mui-icon-colored type="check" color="green" style="flex-shrink:0" />
          <span>Keine offenen Vereinbarungen zu bearbeiten.</span>
        </mui-flex>
      </mui-sheet-inner>

      <div class="p-2" v-else>
        <mui-tile-agreement
          v-for="agreement in agreements"
          :key="agreement.id"
          :agreement="agreement"
          @click="goToAgreement(agreement)"
        />
        <div class="p-6">
          … und {{ allAgreementsCount - agreements.length }} weitere.
        </div>
      </div>
    </template>
  </mui-sheet>
</template>

<script>
import { get } from '@/utils/api';

export default {
  data() {
    return {
      isLoading: true,
      agreements: [],

      allAgreementsCount: 0,

      maxAgreementsToShow: 5,
    };
  },

  methods: {
    async loadAgreements() {
      const responseDrafts = await get('/agreements', {
        filter: {
          drafts: true,
          forMe: true,
        },
        include: 'service,business',
        append: 'status',
      });

      const responseNotSigned = await get('/agreements', {
        filter: {
          notSigned: true,
          forMe: true,
        },
        include: 'service,business',
        append: 'status',
      });

      const allAgreements = [...responseNotSigned.data.data, ...responseDrafts.data.data];
      const shortenedAgreements = allAgreements.slice(0, this.maxAgreementsToShow);

      this.allAgreementsCount = allAgreements.length;
      this.agreements = shortenedAgreements;

      this.isLoading = false;
    },

    goToAgreements() {
      this.$router.push({
        name: 'agreements',
      });
    },

    goToAgreement(agreement) {
      this.$router.push({
        name: 'agreement-details',
        params: { id: agreement.id },
      });
    },
  },

  mounted() {
    this.loadAgreements();
  },
};
</script>
