<template>
  <mui-field-number
    :min="0"
    suffix="EUR"
    :step="0.01"
    :button-step="0.5"
    :decimals="2"
    :required="required"
    v-bind="$props"
    v-on="$listeners"
    v-model="$attrs.value"
  />
</template>

<script>
import Field from '@/mixins/Field';

export default {
  mixins: [Field],
};
</script>
