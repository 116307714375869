<template>
  <div>
    <mui-flex class="mb-8">
      <mui-button-group>
        <mui-button icon="arrow-left" @click="previousDate" :disabled="isPreviousDisabled" />

        <mui-dropdown>
          <mui-button
            icon="calendar"
            theme="ghost-highlight"
            @click="$refs.datePicker.toggle()"
            :disabled="isLoading"
          >
            {{ currentEventsTitle }}
          </mui-button>

          <mui-dropdown-content align="left" size="datepicker" ref="datePicker">
            <mui-datepicker
              :value="selectedDate"
              :min="data.first_event && data.first_event.length ? data.first_event[0].start_date : null"
              :max="data.latest_event && data.latest_event.length ? data.latest_event[0].start_date : null"
              @input="changeDateFromDatepicker"
            />
          </mui-dropdown-content>
        </mui-dropdown>

        <mui-button icon="arrow-right" @click="nextDate" :disabled="isNextDisabled" />
      </mui-button-group>

      <div class="ml-auto">
        <mui-selection
          v-model="selectedRange"
          :options="rangeOptions"
          returned-by="value"
          :disabled="isLoading"
        />
      </div>
    </mui-flex>

    <mui-loader v-if="isLoading" />
    <mui-grid v-else-if="groupedCollection.length" gutter="large">
      <mui-col v-for="(group, groupKey) in groupedCollection" :key="groupKey">
        <mui-label v-if="group.service && group.service.title">
          {{ group.service.title }}
        </mui-label>
        <mui-label v-else>
          (ohne Leistung)
        </mui-label>

        <mui-grid gutter="small">
          <mui-col
            width="1/3"
            v-for="(event, eventKey) in group.events"
            :key="eventKey"
          >
            <mui-profile-event :event="event" />
          </mui-col>
        </mui-grid>
      </mui-col>
    </mui-grid>
    <div v-else>
      <mui-notification type="empty">
        Keine Termine im gewählten Zeitraum gefunden.
      </mui-notification>
    </div>
  </div>
</template>

<script>
import IsCollection from '@/mixins/IsCollection';
import {
  startOfWeek,
  startOfMonth,
  startOfQuarter,
  endOfWeek,
  endOfMonth,
  endOfQuarter,
  add,
  sub,
  isBefore,
  isAfter,
} from 'date-fns';
import datefns from '@/helpers/datefns';
import arrays from '@/helpers/arrays';

export default {
  mixins: [IsCollection],

  props: {
    data: {
      type: Object,
      default: null,
    },

    type: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      resource: this.data,

      selectedDate: new Date(),
      selectedRange: 'month',

      rangeOptions: [
        { label: 'Tag', value: 'day' },
        { label: 'Woche', value: 'week' },
        { label: 'Monat', value: 'month' },
        { label: 'Quartal', value: 'quarter' },
      ],

      endpoint: this.data.id ? '/events' : null,
      queryParameters: {
        sort: 'start_date,start_time',
        include: 'service,horses,employees',
        append: 'color',
        filter: {},
      },
    };
  },

  computed: {
    parametersToQueryData() {
      const parameters = JSON.parse(JSON.stringify(this.queryParameters));

      parameters.filter.after = datefns.toSqlDate(this.from);
      parameters.filter.before = datefns.toSqlDate(this.until);

      if (this.type === 'customer') {
        parameters.filter.hasCustomers = this.data.id;
      }
      if (this.type === 'employee') {
        parameters.filter.hasEmployees = this.data.id;
      }
      if (this.type === 'horse') {
        parameters.filter.hasHorses = this.data.id;
      }

      return parameters;
    },

    groupedCollection() {
      if (!this.collection.length) return [];

      const groupedCollection = arrays.groupBy(this.collection, 'service_id');
      const sortedCollection = [];

      Object.keys(groupedCollection).forEach((key) => {
        sortedCollection.push({
          service: groupedCollection[key][0].service,
          events: groupedCollection[key],
        });
      });

      return sortedCollection;
    },

    currentEventsTitle() {
      if (this.from === this.until) {
        return datefns.toLongString(this.from);
      }

      const from = datefns.toLongString(this.from);
      const until = datefns.toLongString(this.until);

      return `${from} bis ${until}`;
    },

    from() {
      if (this.selectedRange === 'week') {
        return startOfWeek(this.selectedDate, datefns.DATE_OPTIONS);
      }
      if (this.selectedRange === 'month') {
        return startOfMonth(this.selectedDate);
      }
      if (this.selectedRange === 'quarter') {
        return startOfQuarter(this.selectedDate);
      }

      return this.selectedDate;
    },

    until() {
      if (this.selectedRange === 'week') {
        return endOfWeek(this.selectedDate, datefns.DATE_OPTIONS);
      }
      if (this.selectedRange === 'month') {
        return endOfMonth(this.selectedDate);
      }
      if (this.selectedRange === 'quarter') {
        return endOfQuarter(this.selectedDate);
      }

      return this.selectedDate;
    },

    range() {
      if (this.selectedRange === 'week') {
        return { weeks: 1 };
      }
      if (this.selectedRange === 'month') {
        return { months: 1 };
      }
      if (this.selectedRange === 'quarter') {
        return { months: 3 };
      }

      return { days: 1 };
    },

    isPreviousDisabled() {
      if (this.isLoading) {
        return true;
      }

      if (this.data.first_event && this.data.first_event.length) {
        const firstEvent = datefns.toDate(this.data.first_event[0].start_date);
        return isBefore(sub(this.selectedDate, this.range), firstEvent);
      }

      return false;
    },

    isNextDisabled() {
      if (this.isLoading) {
        return true;
      }

      if (this.data.latest_event && this.data.latest_event.length) {
        const latestEvent = datefns.toDate(this.data.latest_event[0].start_date);
        return isAfter(add(this.selectedDate, this.range), latestEvent);
      }

      return false;
    },
  },

  methods: {
    previousDate() {
      const newDate = sub(this.selectedDate, this.range);
      this.changeDate(newDate);
    },

    nextDate() {
      const newDate = add(this.selectedDate, this.range);
      this.changeDate(newDate);
    },

    changeDate(date) {
      this.selectedDate = date;
      this.loadData();
    },

    changeDateFromDatepicker(date) {
      this.$refs.datePicker.close();
      this.changeDate(date);
    },
  },

  watch: {
    selectedRange() {
      this.loadData();
    },
  },

  mounted() {
    this.initCollection();
  },
};
</script>
