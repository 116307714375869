<template>
  <mui-tile
    icon="file-invoice"
    ref="tile"
    @click="$emit('click')"
    :subline="computedSubline"
  >
    <strong slot="title">
      {{ invoice.nr ? invoice.nr : 'Entwurf' }}
    </strong>

    <div class="pr-4 pt-8 pl-2" slot="actions" v-if="status">
      <mui-strings-invoice-status :invoice="invoice" :with-text="false" />
    </div>
  </mui-tile>
</template>

<script>
import datefns from '@/helpers/datefns';

export default {
  props: {
    invoice: {
      type: Object,
      default: null,
    },

    subline: {
      type: String,
      default: null,
    },

    status: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    computedSubline() {
      if (this.subline) {
        return this.subline;
      }

      return `${this.formattedDate} · EUR ${this.formattedPrice}`;
    },

    formattedPrice() {
      if (this.invoice.totalgross) {
        return parseFloat(this.invoice.totalgross).toLocaleString(undefined, { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
      }

      return '0,00';
    },

    formattedDate() {
      if (this.invoice.date) {
        return datefns.toFormat(this.invoice.date, 'P');
      }

      return null;
    },
  },
};
</script>
