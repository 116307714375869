<template>
  <form class="mui-form">
    <mui-fieldset :with-margin="false">
      <mui-grid gutter="large">
        <mui-col :width="resource.allday ? '1/1' : '1/2'" mobile-width="1/1">
          <mui-grid gutter="small">
            <mui-col :width="resource.allday ? '5/12' : '1/1'" mobile-width="1/1">
              <mui-field-date
                label="Datum"
                icon="calendar-alt"
                placeholder="Startdatum"
                v-model="resource.start_date"
                :readable="false"
                :edit="true"
                :has-error="hasFieldErrors('start_date')"
                :info="getFieldErrors('start_date')"
              />
            </mui-col>

            <mui-col width="1/6" v-if="resource.allday" class="hide-on-mobile">
              <mui-button-group style="justify-content:center" class="mt-10">
                <mui-button theme="ghost-static" icon="arrow-right" />
              </mui-button-group>
            </mui-col>

            <mui-col width="5/12" v-if="resource.allday" mobile-width="1/1">
              <mui-field-date
                :label="this.$mq.breakpoint !== 'mobile' ? ' ' : null"
                icon="calendar-alt"
                placeholder="Enddatum"
                v-model="resource.end_date"
                :readable="false"
                :edit="true"
                :has-error="hasFieldErrors('end_date')"
                :info="getFieldErrors('end_date')"
                datepicker-align="right"
                :min="resource.start_date"
              />
            </mui-col>

            <mui-col>
              <mui-input-checkbox
                v-model="resource.allday"
                text="Ganztägig"
              />
            </mui-col>
          </mui-grid>
        </mui-col>

        <mui-col width="1/2" v-if="!resource.allday" mobile-width="1/1">
          <mui-grid gutter="small">
            <mui-col width="1/2" mobile-width="1/1">
              <mui-field-time
                label="Von"
                placeholder="10:00"
                v-model="resource.start_time"
                :min="minTime"
                :max="maxTime"
                :readable="false"
                :edit="true"
                :has-error="hasFieldErrors('start_time')"
                :info="getFieldErrors('start_time')"
              />
            </mui-col>

            <mui-col width="1/2" mobile-width="1/1">
              <mui-field-time
                label="Bis"
                placeholder="11:00"
                v-model="resource.end_time"
                :min="minEndTime"
                :max="maxTime"
                :readable="false"
                :edit="true"
                :has-error="hasFieldErrors('end_time')"
                :info="getFieldErrors('end_time')"
              />
            </mui-col>

            <mui-col class="pt-4 hide-on-mobile" align="right" v-if="resource.start_time">
              <mui-shield :text="durationAsString" />
            </mui-col>
          </mui-grid>
        </mui-col>
      </mui-grid>
    </mui-fieldset>

    <mui-fieldset :with-margin="false" v-if="withRepetition">
      <mui-col v-if="!multipleDays && isNew">
        <mui-input-checkbox
          v-model="resource.is_repeating"
          text-false="Termin wird nicht wiederholt"
          :text-true="`Termin wird ${repetitionPattern} wiederholt`"
        />
      </mui-col>

      <mui-expander :is-expanded="resource.is_repeating && !multipleDays">
        <mui-grid size="large" :class="isNew ? 'mt-12' : null">
          <mui-col width="1/2">
            <mui-field-select
              label="Frequenz"
              v-model="resource.repetition.frequency"
              :options="repetitionFrequencies"
              :return-object="false"
              :readable="false"
              :edit="true"
              :has-error="hasFieldErrors('repetition.frequency')"
              :info="getFieldErrors('repetition.frequency')"
            />
          </mui-col>

          <mui-col width="1/2">
            <mui-field-number
              label="Interval"
              placeholder="1"
              size="smallest"
              v-model="resource.repetition.interval"
              :min="1"
              :max="20"
              :readable="false"
              :edit="true"
              :has-error="hasFieldErrors('repetition.interval')"
              :info="getFieldErrors('repetition.interval')"
            />
          </mui-col>

          <mui-col width="1/2">
            <mui-field-select
              label="Wiederholungen begrenzen"
              v-model="resource.repetition.is_ending"
              :options="[
                { label: 'Nicht begrenzen', value: 'null' },
                { label: 'Nach Anzahl', value: 'occurences' },
                { label: 'Nach Datum', value: 'date' }
              ]"
              :readable="false"
              :edit="true"
              :has-error="hasFieldErrors('repetition.is_ending')"
              :info="getFieldErrors('repetition.is_ending')"
            />
          </mui-col>

          <mui-col
            width="1/2"
            v-if="resource.repetition.is_ending && resource.repetition.is_ending.value === 'occurences'"
          >
            <mui-field-number
              label="Anzahl"
              placeholder="10"
              size="smallest"
              v-model="resource.repetition.occurences"
              :min="1"
              :readable="false"
              :edit="true"
              :has-error="hasFieldErrors('repetition.occurences')"
              :info="getFieldErrors('repetition.occurences')"
            />
          </mui-col>

          <mui-col
            width="1/2"
            v-if="resource.repetition.is_ending && resource.repetition.is_ending.value === 'date'"
          >
            <mui-field-date
              label="Enddatum"
              icon="calendar-alt"
              placeholder="Enddatum"
              v-model="resource.repetition.end_date"
              :readable="false"
              :edit="true"
              :has-error="hasFieldErrors('repetition.end_date')"
              :info="getFieldErrors('repetition.end_date')"
              datepicker-align="right"
              :min="resource.start_date"
            />
          </mui-col>
        </mui-grid>
      </mui-expander>
    </mui-fieldset>

    <mui-fieldset :with-margin="false">
      <mui-grid size="large">
        <mui-col>
          <mui-field-customers
            label="Kunden"
            v-model="resource.customers"
            :readable="false"
            :edit="true"
            :has-error="hasFieldErrors('customers')"
            :info="getFieldErrors('customers')"
          />
        </mui-col>

        <mui-col v-if="resource.customers.length > 1">
          <mui-notification type="warning">
            Dieser Termin wird als Gruppentermin angelegt. Sofern die ausgewählten Kunden nicht alle über die gesamte Dauer des Termins betreut werden, bitte einzelne Termine anlegen.
          </mui-notification>
        </mui-col>

        <mui-col>
          <mui-field-employees
            label="Mitarbeiter"
            v-model="resource.employees"
            :required="needsService"
            :readable="false"
            :edit="true"
            :has-error="hasFieldErrors('employees')"
            :info="getFieldErrors('employees')"
          />
        </mui-col>

        <mui-col>
          <mui-field-horses
            label="Pferde"
            v-model="resource.horses"
            :readable="false"
            :edit="true"
            :has-error="hasFieldErrors('horses')"
            :info="getFieldErrors('horses')"
          />
        </mui-col>
      </mui-grid>
    </mui-fieldset>

    <mui-fieldset :with-margin="false">
      <mui-grid size="large">
        <mui-col width="1/2" v-if="needsService">
          <mui-field-services
            label="Leistung"
            v-model="service"
            :readable="false"
            :edit="true"
            :has-error="hasFieldErrors('service_id')"
            :info="getFieldErrors('service_id')"
            :multiple="false"
            :required="true"
          />
        </mui-col>

        <mui-col :width="needsService ? '1/2' : '1/1'">
          <mui-field-text
            label="Titel"
            :placeholder="needsService ? '(wird automatisch generiert)' : 'Titel eingeben'"
            v-model="resource.title"
            :readable="false"
            :edit="true"
            :has-error="hasFieldErrors('title')"
            :info="getFieldErrors('title')"
            :required="!needsService"
          />
        </mui-col>

        <mui-col width="1/2">
          <mui-field-places
            label="Raum"
            v-model="place"
            :readable="false"
            :edit="true"
            :has-error="hasFieldErrors('place_id')"
            :info="getFieldErrors('place_id')"
            :multiple="false"
          />
        </mui-col>

        <mui-col width="1/2">
          <mui-field-materials
            label="Materialien"
            v-model="resource.materials"
            :readable="false"
            :edit="true"
            :has-error="hasFieldErrors('materials')"
            :info="getFieldErrors('materials')"
            :multiple="true"
          />
        </mui-col>
      </mui-grid>
    </mui-fieldset>
  </form>
</template>

<script>
import config from '@/config';
import timesutil from '@/helpers/times';
import { textpattern, frequencies } from '@/helpers/repetition';

import Form from '@/mixins/Form';

export default {
  mixins: [Form],

  props: {
    data: {
      type: Object,
      default: null,
    },

    withRepetition: {
      type: Boolean,
      default: false,
    },

    isNew: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      resource: this.data,

      service: null,
      place: null,

      duration: 30,
      minDuration: 15,

      repetitionFrequencies: frequencies(),

      initialized: false,
    };
  },

  computed: {
    startDate() {
      return this.resource.start_date;
    },

    endDate() {
      return this.resource.end_date;
    },

    durationAsString() {
      if (this.duration > 120) {
        return `${timesutil.toString(this.duration)} Stunden`;
      }

      return `${this.duration} Minuten`;
    },

    startTime() {
      return this.resource.start_time;
    },

    endTime() {
      return this.resource.end_time;
    },

    allday() {
      return this.resource.allday;
    },

    multipleDays() {
      return this.allday && (this.startDate !== this.endDate);
    },

    minTime() {
      return config.app.calendar_from;
    },

    maxTime() {
      return config.app.calendar_until;
    },

    minEndTime() {
      if (this.hasStartTime) {
        const start = timesutil.toMinutes(this.resource.start_time);
        const minEndTime = timesutil.toString(start + this.minDuration);

        return minEndTime;
      }

      return config.app.calendar_from;
    },

    hasStartTime() {
      return !!this.resource.start_time;
    },

    hasEndTime() {
      return !!this.resource.end_time;
    },

    hasRepetition() {
      return !!this.resource.repetition;
    },

    needsService() {
      return !!(this.resource.customers && this.resource.customers.length);
    },

    repetitionPattern() {
      if (this.resource.is_repeating && this.resource.repetition) {
        return textpattern(this.resource.repetition);
      }

      return 'nicht';
    },
  },

  watch: {
    allday() {
      if (!this.initialized) {
        return;
      }

      if (this.allday) {
        this.resource.end_date = this.resource.start_date;
      }
    },

    startDate() {
      if (!this.initialized) {
        return;
      }

      if (!this.isNew) {
        if (!this.allday) {
          this.resource.end_date = this.resource.start_date;
        }
      }
    },

    startTime() {
      if (!this.initialized) {
        return;
      }

      const start = timesutil.toMinutes(this.startTime);
      const end = timesutil.toString(start + this.duration);

      this.resource.end_time = end;
    },

    endTime() {
      if (!this.initialized) {
        return;
      }

      const difference = timesutil.toDifference(this.startTime, this.endTime);
      this.duration = difference;
    },

    service() {
      if (!this.initialized) {
        return;
      }

      if (this.service) {
        this.resource.service_id = this.service.id;
      } else {
        this.resource.service_id = null;
      }
    },

    place() {
      if (!this.initialized) {
        return;
      }

      if (this.place) {
        this.resource.place_id = this.place.id;
      } else {
        this.resource.place_id = null;
      }
    },
  },

  created() {
    if (!this.isNew) {
      if (this.resource.start_time && this.resource.end_time) {
        const startTime = timesutil.toTime(this.resource.start_time);
        const endTime = timesutil.toTime(this.resource.end_time);

        this.duration = timesutil.toDifference(startTime, endTime);

        this.resource.start_time = timesutil.toTimeString(startTime);
        this.resource.end_time = timesutil.toTimeString(endTime);
      } else {
        this.resource.allday = true;
      }

      if (this.resource.service) {
        this.service = this.resource.service;
      }

      if (this.resource.place) {
        this.place = this.resource.place;
      }

      if (this.resource.repetition) {
        this.resource.is_repeating = true;

        if (this.resource.repetition.end_date) {
          this.resource.repetition.is_ending = { value: 'date', label: 'Nach Datum' };
        } else if (this.resource.repetition.occurences) {
          this.resource.repetition.is_ending = { value: 'occurences', label: 'Nach Anzahl' };
        }
      }
    }

    this.initialized = true;
  },
};
</script>
