<template>
  <mui-field
    :label="value.title"
    :info="info"
    :has-error="hasError"
    :has-warning="hasWarning"
    :edit="isEditing"
    :editable="editable"
    :readable="readable"
    :pinnable="pinnable"
    :pinned="pinned"
    :removable="removable"
    :show-field-label-on-edit="false"
    @save="onSave"
    @pin="onPin"
    @remove="onRemove"
  >
    <template slot="edit">
      <mui-grid>
        <mui-col width="2/3">
          <mui-input label="Nummer" :icon="fieldIcon">
            <input
              class="mui-input-element"
              placeholder="0170 2005841"
              v-model="value.phone"
              type="phone"
              autofocus
            >
          </mui-input>
        </mui-col>

        <mui-col width="1/3">
          <mui-input label="Bezeichnung">
            <input
              class="mui-input-element"
              placeholder="Bezeichnung"
              v-model="value.title"
              list="defaultTitles-phones"
            >

            <datalist id="defaultTitles-phones">
              <option value="Festnetz" />
              <option value="Mobil" />
              <option value="Arbeit" />
              <option value="Sonstige" />
            </datalist>
          </mui-input>
        </mui-col>
      </mui-grid>
    </template>

    <mui-data-element-phone
      placeholder="0170 2005841"
      :number="value.phone"
      :icon="fieldIcon"
      slot="read"
    />
  </mui-field>
</template>

<script>
import phoneutils from '@/helpers/phone';
import field from '@/mixins/Field';

export default {
  mixins: [field],

  computed: {
    fieldIcon() {
      switch (phoneutils.type(this.parsedPhone)) {
        case 'FIXED_LINE':
          return 'phone-rotary';

        case 'MOBILE':
          return 'mobile';

        default:
          return 'phone';
      }
    },

    parsedPhone() {
      return phoneutils.parse(this.value.phone);
    },
  },

  methods: {
    onSave() {
      this.value.phone = this.parsedPhone.number;
      this.$emit('save');
    },
  },

  watch: {
    // eslint-disable-next-line object-shorthand
    'value.phone'() {
      if (!this.value.title || this.value.title === '') {
        let title = '';

        if (this.parsedPhone) {
          switch (this.parsedPhone.getType()) {
            case 'FIXED_LINE':
              title = 'Festnetz';
              break;

            case 'MOBILE':
              title = 'Mobil';
              break;

            case undefined:
              title = '';
              break;

            default:
              title = 'Telefon';
              break;
          }
        }

        this.value.title = title;
      }
    },
  },
};
</script>
