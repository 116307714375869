<template>
  <mui-dropdown>
    <mui-field
      :label="label"
      :icon="icon"
      :info="info"
      :has-error="hasError"
      :has-warning="hasWarning"
      :id="id"
      :required="required"
      :edit="edit"
      :editable="editable"
      :readable="readable"
      :pinnable="pinnable"
      :removable="removable"
      @save="onSave"
      @pin="onPin"
      @remove="onRemove"
    >
      <span
        class="mui-input-element -formatted-date -shadow-input"
        :id="id"
        @click="$refs.datePicker.toggle()"
      >
        <template v-if="selectedDate">{{ formattedDate }}</template>
        <span class="color-grey-dark" v-else>{{ placeholder }}</span>
      </span>
      <span class="mui-input-actions" v-if="!required && selectedDate">
        <mui-button icon="times-circle" theme="inside-input" @click.prevent="selectedDate = null" />
      </span>

      <mui-dropdown-content :align="datepickerAlign" size="datepicker" ref="datePicker">
        <mui-datepicker
          :value="selectedDate"
          @input="changeDateFromDatepicker"
          :min="min"
          :max="max"
        />
      </mui-dropdown-content>

      <slot name="read" slot="read">
        <mui-data-element-date
          :icon="icon"
          :text="sqlDate"
          :placeholder="placeholder"
        />
      </slot>
    </mui-field>
  </mui-dropdown>
</template>

<script>
import field from '@/mixins/Field';
import datefns from '@/helpers/datefns';

export default {
  mixins: [field],

  props: {
    placeholder: {
      type: String,
      default: 'Datum wählen',
    },

    datepickerAlign: {
      type: String,
      default: 'left',
    },

    min: {
      type: String,
      default: null,
    },

    max: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      selectedDate: datefns.toDate(this.$attrs.value),
    };
  },

  computed: {
    formattedDate() {
      return datefns.toShortString(this.selectedDate);
    },

    sqlDate() {
      return datefns.toSqlDate(this.selectedDate);
    },
  },

  methods: {
    changeDateFromDatepicker(date) {
      this.selectedDate = date;
      this.$refs.datePicker.close();
    },
  },

  watch: {
    selectedDate() {
      this.$emit('input', this.sqlDate);
    },
  },
};
</script>
