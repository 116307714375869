var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('mui-loader'):_c('mui-field-select',_vm._g(_vm._b({attrs:{"options":_vm.collection,"required":_vm.required,"labelled-by":"fullname","tracked-by":"id","described-by":"id","placeholder":"Kunden auswählen","searchable":"","search-only":"","search-through":['id', 'name', 'firstname', 'organization', 'prefix'],"search-after":2,"multiple":_vm.multiple,"taggable":_vm.multiple},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var selection = ref.selection;
var remove = ref.remove;
return (_vm.multiple)?[_c('span',{staticClass:"mui-select-selection"},_vm._l((selection),function(person){return _c('mui-tag',{key:person.id,attrs:{"removeable":""},on:{"remove":function($event){return remove(person)}}},[_c('mui-strings-name',{attrs:{"person":person}})],1)}),1)]:undefined}},{key:"option",fn:function(ref){
var option = ref.option;
var selectOption = ref.selectOption;
var isSelected = ref.isSelected;
return [_c('mui-button',{class:{
        'mui-select-option': true,
        '-is-active': isSelected(option)
      },on:{"click":function($event){$event.preventDefault();return selectOption(option)}}},[_c('mui-strings-name',{attrs:{"person":option}}),_vm._v(" "),_c('span',{staticClass:"mui-select-option-description"},[_vm._v("\n        Nr. "+_vm._s(option.id)+"\n      ")])],1)]}}],null,true),model:{value:(_vm.$attrs.value),callback:function ($$v) {_vm.$set(_vm.$attrs, "value", $$v)},expression:"$attrs.value"}},'mui-field-select',_vm.$props,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }