import axios from 'axios';
import config from '@/config';
import { getHeaders } from './http-helper';

const http = axios.create({
  baseURL: config.api.endpoint,
  headers: getHeaders(),
  withCredentials: true,
});

export default http;
