<template>
  <mui-loader v-if="isLoading" />
  <mui-field-select
    v-else
    :options="collection"
    :required="required"
    labelled-by="name"
    tracked-by="id"
    placeholder="Pferd auswählen"
    searchable
    :search-through="['id', 'name']"
    multiple
    taggable
    v-bind="$props"
    v-on="$listeners"
    v-model="$attrs.value"
  >
    <template slot="selection" slot-scope="{ selection, remove }">
      <span class="mui-select-selection">
        <mui-tag
          v-for="person in selection"
          :key="person.id"
          removeable
          @remove="remove(person)"
        >
          <mui-strings-name
            :person="person"
            :address="true"
            :emphasize="false"
          />
        </mui-tag>
      </span>
    </template>
  </mui-field-select>
</template>

<script>
import IsCollection from '@/mixins/IsCollection';
import Field from '@/mixins/Field';

export default {
  mixins: [IsCollection, Field],

  data() {
    return {
      endpoint: '/horses',

      queryParameters: {
        fields: {
          horses: 'id,name',
        },
      },
    };
  },

  mounted() {
    this.initCollection();
  },
};
</script>
