import {
  parsePhoneNumberFromString as _parsePhoneNumberFromString,
} from 'libphonenumber-js/core';

import metadata from '@/setup/libphonenumber.meta.json';


const parse = (string, country = 'DE') => {
  if (!string) return null;
  return _parsePhoneNumberFromString(string, country, metadata);
};

const type = (phoneObj) => {
  if (!phoneObj) return false;
  if (typeof phoneObj !== 'object') return false;

  return phoneObj.getType();
};


export default {
  parse,
  type,
};
