<template>
  <mui-loader v-if="isLoading" />
  <mui-field
    v-else
    :label="label"
    :icon="icon"
    :info="info"
    :has-error="hasError"
    :has-warning="hasWarning"
    :id="id"
    :required="required"
    :edit="edit"
    :editable="editable"
    :readable="readable"
    :pinnable="pinnable"
    :removable="removable"
    @save="onSave"
    @pin="onPin"
    @remove="onRemove"
  >
    <mui-selection
      :options="collection"
      multiple
      gridded
      v-model="selection"
      class="-shadow-input"
    >
      <span class="mui-selection-option-inner" slot-scope="{ option }">
        <span class="mui-selection-option-content">
          <strong>{{ formatDate(option) }}</strong>
          {{ formatTime(option) }}
        </span>
      </span>
    </mui-selection>

    <slot name="read" slot="read">
      <mui-grid gutter="tiny">
        <mui-col
          v-for="event in selection"
          :key="event.id"
          width="1/4"
        >
          <mui-tile>
            <template slot="title">
              <strong>{{ formatDate(event) }}</strong><br>
              {{ formatTime(event) }}
            </template>
          </mui-tile>
        </mui-col>
      </mui-grid>
    </slot>
  </mui-field>
</template>

<script>
import IsCollection from '@/mixins/IsCollection';
import Field from '@/mixins/Field';

import datefns from '@/helpers/datefns';
import times from '@/helpers/times';

export default {
  mixins: [IsCollection, Field],

  props: {
    parameters: {
      type: Object,
      default: null,
    },

    multiple: {
      type: Boolean,
      default: true,
    },

    options: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      endpoint: '/events',

      queryParameters: this.parameters,

      selection: this.$attrs.value,
    };
  },

  methods: {
    isWholeDay(event) {
      return (!event.start_time || !event.end_time);
    },

    formatDate(event) {
      return datefns.toShortString(event.start_date);
    },

    formatTime(event) {
      if (this.isWholeDay(event)) {
        return null;
      }

      const start = times.toString(times.toMinutes(event.start_time));
      const end = times.toString(times.toMinutes(event.end_time));

      return `${start} – ${end} Uhr`;
    },

    formatEmployees(event) {
      return event.employees.map((employee) => `${employee.firstname} ${employee.name}`).join(', ');
    },
  },

  watch: {
    selection() {
      this.$emit('input', this.selection);
    },
  },

  mounted() {
    this.initCollection();
  },
};
</script>
