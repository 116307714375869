import store from '@/setup/store';

export default [
  {
    path: '/',
    name: 'home',
    redirect: '/dashboard',
  },

  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
    meta: {
      outside: true,
    },
  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter(to, from, next) {
      store.dispatch('system/resetLocalViews');
      store.dispatch('auth/logout');
      next('/login');
    },
    meta: {
      outside: true,
    },
  },

  /**
   * APP ROUTES
   */
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
  },

  {
    path: '/calendar/:date?/:eventId?',
    name: 'calendar',
    component: () => import(/* webpackChunkName: "calendar" */ '@/views/Calendar.vue'),
    meta: { permissions: ['events.index'] },
  },

  {
    path: '/events',
    name: 'events',
    component: () => import(/* webpackChunkName: "events" */ '@/views/EventsCollection.vue'),
    meta: { permissions: ['events.index'] },
  },

  {
    path: '/reservations',
    name: 'reservations',
    component: () => import(/* webpackChunkName: "reservations" */ '@/views/ReservationsCollection.vue'),
    meta: { permissions: ['events.index'] },
  },

  {
    path: '/reports',
    name: 'reports',
    component: () => import(/* webpackChunkName: "reports" */ '@/views/NotAllowed.vue'),
    meta: { permissions: ['reports.index'] },
  },

  {
    path: '/statistics',
    name: 'statistics',
    component: () => import(/* webpackChunkName: "statistics" */ '@/views/Statistics.vue'),
    meta: { permissions: ['statistics.index'] },
  },

  {
    path: '/agreements',
    name: 'agreements',
    component: () => import(/* webpackChunkName: "agreements" */ '@/views/AgreementsCollection.vue'),
    meta: { permissions: ['agreements.index'] },
  },
  {
    path: '/agreements/:id',
    name: 'agreement-details',
    component: () => import(/* webpackChunkName: "agreements" */ '@/views/AgreementsResource.vue'),
    meta: { permissions: ['agreements.show'] },
  },

  {
    path: '/customers',
    name: 'customers',
    component: () => import(/* webpackChunkName: "customers" */ '@/views/CustomersCollection.vue'),
    meta: { permissions: ['customers.index'] },
  },
  {
    path: '/customers/:id',
    component: () => import(/* webpackChunkName: "customer" */ '@/views/CustomersResource.vue'),

    children: [
      {
        path: '',
        name: 'customer-overview',
        meta: { permissions: ['customers.show'] },
        component: () => import(/* webpackChunkName: "customer" */ '@/views/CustomersResourceOverview.vue'),
      },
      {
        path: 'contact',
        name: 'customer-contact',
        meta: { permissions: ['customers.show'] },
        component: () => import(/* webpackChunkName: "customer" */ '@/views/CustomersResourceContact.vue'),
      },
      {
        path: 'events',
        name: 'customer-events',
        meta: { permissions: ['customers.show'] },
        component: () => import(/* webpackChunkName: "customer" */ '@/views/CustomersResourceEvents.vue'),
      },
      {
        path: 'progress',
        name: 'customer-progress',
        meta: { permissions: ['customers.show'] },
        component: () => import(/* webpackChunkName: "customer" */ '@/views/CustomersResourceProgress.vue'),
      },
      {
        path: 'documents',
        name: 'customer-documents',
        meta: { permissions: ['customers.show'] },
        component: () => import(/* webpackChunkName: "customer" */ '@/views/CustomersResourceDocuments.vue'),
      },
      {
        path: 'preferences',
        name: 'customer-preferences',
        meta: { permissions: ['customers.show'] },
        component: () => import(/* webpackChunkName: "customer" */ '@/views/CustomersResourcePreferences.vue'),
      },
      {
        path: 'files',
        name: 'customer-files',
        meta: { permissions: ['customers.show'] },
        component: () => import(/* webpackChunkName: "customer" */ '@/views/CustomersResourceFiles.vue'),
      },
      {
        path: 'activities',
        name: 'customer-activities',
        meta: { permissions: ['customers.show'] },
        component: () => import(/* webpackChunkName: "customer" */ '@/views/CustomersResourceActivities.vue'),
      },
    ],
  },

  {
    path: '/employees',
    name: 'employees',
    component: () => import(/* webpackChunkName: "employees" */ '@/views/EmployeesCollection.vue'),
    meta: { permissions: ['employees.index'] },
  },
  {
    path: '/employees/:id',
    component: () => import(/* webpackChunkName: "employee" */ '@/views/EmployeesResource.vue'),

    children: [
      {
        path: '',
        name: 'employee-overview',
        meta: { permissions: ['employees.show'] },
        component: () => import(/* webpackChunkName: "employee" */ '@/views/EmployeesResourceOverview.vue'),
      },
      {
        path: 'contact',
        name: 'employee-contact',
        meta: { permissions: ['employees.show'] },
        component: () => import(/* webpackChunkName: "employee" */ '@/views/EmployeesResourceContact.vue'),
      },
      {
        path: 'profile',
        name: 'employee-profile',
        meta: { permissions: ['employees.profile'] },
        component: () => import(/* webpackChunkName: "employee" */ '@/views/EmployeesResourceProfile.vue'),
      },
      {
        path: 'events',
        name: 'employee-events',
        meta: { permissions: ['employees.show'] },
        component: () => import(/* webpackChunkName: "employee" */ '@/views/EmployeesResourceEvents.vue'),
      },
      {
        path: 'files',
        name: 'employee-files',
        meta: { permissions: ['employees.show'] },
        // component: () => import(/* webpackChunkName: "employee" */ '@/views/EmployeesResourceFiles.vue'),
      },
      {
        path: 'activities',
        name: 'employee-activities',
        meta: { permissions: ['employees.show'] },
        // component: () => import(/* webpackChunkName: "employee" */ '@/views/EmployeesResourceActivities.vue'),
      },
    ],
  },

  {
    path: '/horses',
    name: 'horses',
    component: () => import(/* webpackChunkName: "horses" */ '@/views/HorsesCollection.vue'),
    meta: { permissions: ['horses.index'] },
  },
  {
    path: '/horses/:id',
    component: () => import(/* webpackChunkName: "horse" */ '@/views/HorsesResource.vue'),

    children: [
      {
        path: '',
        name: 'horse-overview',
        meta: { permissions: ['horses.show'] },
        component: () => import(/* webpackChunkName: "horse" */ '@/views/HorsesResourceProfile.vue'),
      },
      {
        path: 'profile',
        name: 'horse-profile',
        meta: { permissions: ['horses.profile'] },
        component: () => import(/* webpackChunkName: "horse" */ '@/views/HorsesResourceProfile.vue'),
      },
      {
        path: 'events',
        name: 'horse-events',
        meta: { permissions: ['horses.show'] },
        component: () => import(/* webpackChunkName: "horse" */ '@/views/HorsesResourceEvents.vue'),
      },
      {
        path: 'files',
        name: 'horse-files',
        meta: { permissions: ['horses.show'] },
        // component: () => import(/* webpackChunkName: "horse" */ '@/views/HorsesResourceFiles.vue'),
      },
      {
        path: 'activities',
        name: 'horse-activities',
        meta: { permissions: ['horses.show'] },
        // component: () => import(/* webpackChunkName: "horse" */ '@/views/HorsesResourceActivities.vue'),
      },
    ],
  },

  {
    path: '/contacts',
    name: 'contacts',
    component: () => import(/* webpackChunkName: "contacts" */ '@/views/ContactsCollection.vue'),
    meta: { permissions: ['contacts.index'] },
  },
  {
    path: '/contacts/:id',
    component: () => import(/* webpackChunkName: "contact" */ '@/views/ContactsResource.vue'),

    children: [
      {
        path: '',
        name: 'contact-overview',
        meta: { permissions: ['contacts.show'] },
        component: () => import(/* webpackChunkName: "contact" */ '@/views/ContactsResourceOverview.vue'),
      },
      {
        path: 'contact',
        name: 'contact-contact',
        meta: { permissions: ['contacts.show'] },
        component: () => import(/* webpackChunkName: "contact" */ '@/views/ContactsResourceContact.vue'),
      },
      {
        path: 'files',
        name: 'contact-files',
        meta: { permissions: ['contacts.show'] },
        // component: () => import(/* webpackChunkName: "contact" */ '@/views/ContactsResourceFiles.vue'),
      },
      {
        path: 'activities',
        name: 'contact-activities',
        meta: { permissions: ['contacts.show'] },
        // component: () => import(/* webpackChunkName: "contact" */ '@/views/ContactsResourceActivities.vue'),
      },
    ],
  },

  {
    path: '/invoices',
    name: 'invoices',
    component: () => import(/* webpackChunkName: "invoices" */ '@/views/InvoicesCollection.vue'),
    meta: { permissions: ['invoices.index'] },
  },
  {
    path: '/invoices/:id',
    name: 'invoice-details',
    component: () => import(/* webpackChunkName: "invoices" */ '@/views/InvoicesResource.vue'),
    meta: { permissions: ['invoices.show'] },
  },

  {
    path: '/contracts',
    name: 'contracts',
    component: () => import(/* webpackChunkName: "contracts" */ '@/views/NotAllowed.vue'),
    meta: { permissions: ['contracts.index'] },
  },

  {
    path: '/findings',
    name: 'findings',
    component: () => import(/* webpackChunkName: "findings" */ '@/views/NotAllowed.vue'),
    meta: { permissions: ['findings.index'] },
  },

  {
    path: '/prescriptions',
    name: 'prescriptions',
    component: () => import(/* webpackChunkName: "prescriptions" */ '@/views/NotAllowed.vue'),
    meta: { permissions: ['prescriptions.index'] },
  },

  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ '@/views/UsersCollection.vue'),
    meta: { permissions: ['users.index'] },
  },
  {
    path: '/users/:id',
    component: () => import(/* webpackChunkName: "user" */ '@/views/UsersResource.vue'),
    meta: { permissions: ['users.show'] },
    children: [
      {
        path: '',
        name: 'user-account',
        meta: { permissions: ['users.show'] },
        component: () => import(/* webpackChunkName: "user" */ '@/views/UsersResourceAccount.vue'),
      },
      {
        path: 'activities',
        name: 'user-activities',
        meta: { permissions: ['users.show', 'activities.index'] },
        component: () => import(/* webpackChunkName: "user" */ '@/views/UsersResourceActivities.vue'),
      },
    ],
  },

  {
    path: '/account',
    component: () => import(/* webpackChunkName: "account" */ '@/views/Account.vue'),
    children: [
      {
        path: '',
        name: 'account-account',
        component: () => import(/* webpackChunkName: "account" */ '@/views/AccountAccount.vue'),
      },
      {
        path: 'activities',
        name: 'account-activities',
        component: () => import(/* webpackChunkName: "account" */ '@/views/UsersResourceActivities.vue'),
      },
    ],
  },

  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings.vue'),
    meta: { permissions: ['settings.index'] },
    redirect: '/settings/system',
    children: [
      {
        path: 'system',
        name: 'settings-system',
        meta: { permissions: ['settings.system.index'] },
        component: () => import(/* webpackChunkName: "settings" */ '@/views/SettingsSystem.vue'),
      },
      {
        path: 'places',
        name: 'settings-places',
        meta: { permissions: ['places.index'] },
        component: () => import(/* webpackChunkName: "settings" */ '@/views/SettingsPlaces.vue'),
      },
      {
        path: 'materials',
        name: 'settings-materials',
        meta: { permissions: ['materials.index'] },
        component: () => import(/* webpackChunkName: "settings" */ '@/views/SettingsMaterials.vue'),
      },
      {
        path: 'services',
        name: 'settings-services',
        meta: { permissions: ['services.index'] },
        component: () => import(/* webpackChunkName: "settings" */ '@/views/SettingsServices.vue'),
      },
      {
        path: 'actions',
        name: 'settings-actions',
        meta: { permissions: ['settings.actions.index'] },
        component: () => import(/* webpackChunkName: "settings" */ '@/views/SettingsActions.vue'),
      },
      {
        path: 'businesses',
        name: 'settings-businesses',
        meta: { permissions: ['businesses.index'] },
        component: () => import(/* webpackChunkName: "settings" */ '@/views/SettingsBusinesses.vue'),
      },
      {
        path: 'roles',
        name: 'settings-roles',
        meta: { permissions: ['roles.index'] }, // this permission does not exists, only admins can view and edit roles
        component: () => import(/* webpackChunkName: "settings" */ '@/views/SettingsRoles.vue'),
      },
      {
        path: 'views',
        name: 'settings-views',
        meta: { permissions: ['settings.views.index'] },
        component: () => import(/* webpackChunkName: "settings" */ '@/views/SettingsViews.vue'),
      },
    ],
  },

];
