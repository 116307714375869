<template>
  <mui-field
    :label="label"
    :icon="icon"
    :info="info"
    :has-error="hasError"
    :has-warning="hasWarning"
    :id="id"
    :required="required"
    :edit="edit"
    :editable="editable"
    :readable="readable"
    :pinnable="pinnable"
    :removable="removable"
    @save="onSave"
    @pin="onPin"
    @remove="onRemove"
  >
    <mui-input-number
      slot="edit"
      v-model="inputValue"
      v-bind="$attrs"
      :min="min"
      :max="max"
      :step="step"
      :button-step="buttonStep"
      :decimals="decimals"
      :show-buttons="showButtons"
      :required="required"
      :size="size"
      :prefix="prefix"
      :suffix="suffix"
    />
    <slot name="read" slot="read">
      <mui-data-element-text
        :icon="icon"
        :text="value"
        :placeholder="placeholder"
        :prefix="prefix"
        :suffix="suffix"
      />
    </slot>
  </mui-field>
</template>

<script>
import field from '@/mixins/Field';

export default {
  mixins: [field],

  props: {
    buttonStep: {
      type: Number,
      default: 1,
    },

    step: {
      type: [Number, Boolean],
      default: false,
    },

    min: {
      type: [Number, Boolean],
      default: false,
    },

    max: {
      type: [Number, Boolean],
      default: false,
    },

    decimals: {
      type: Number,
      default: null,
    },

    showButtons: {
      type: Boolean,
      default: true,
    },

    size: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      inputValue: this.$attrs.value,
    };
  },

  watch: {
    inputValue() {
      this.$emit('input', this.inputValue);
    },

    value() {
      this.inputValue = this.value;
    },
  },
};
</script>
