<template>
  <mui-loader v-if="isLoading" />
  <mui-field-select
    v-else
    :options="collection"
    labelled-by="title"
    tracked-by="id"
    described-by="code"
    grouped-by="group"
    placeholder="Leistung auswählen"
    :required="required"
    :search-through="['id', 'title', 'code', 'group']"
    searchable
    :multiple="multiple"
    v-bind="$props"
    v-on="$listeners"
    v-model="$attrs.value"
  />
</template>

<script>
import IsCollection from '@/mixins/IsCollection';
import Field from '@/mixins/Field';

export default {
  mixins: [IsCollection, Field],

  props: {
    multiple: {
      type: Boolean,
      default: true,
    },

    withAll: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      endpoint: '/services',

      queryParameters: {
        fields: {
          services: 'id,title,code,group',
        },
      },
    };
  },

  methods: {
    afterDataLoaded() {
      if (this.withAll) {
        this.collection.unshift({
          title: 'Alle Leistungen',
          id: null,
          code: null,
        });
      }
    },
  },

  mounted() {
    this.initCollection();
  },
};
</script>
