<template>
  <mui-field
    :label="label"
    :icon="icon"
    :info="info"
    :has-error="hasError"
    :has-warning="hasWarning"
    :id="id"
    :required="required"
    :edit="isEditing"
    :editable="editable"
    :readable="readable"
    :pinnable="pinnable"
    :removable="removable"
    @save="onSave"
    @pin="onPin"
    @remove="onRemove"
  >
    <input
      class="mui-input-element"
      :autofocus="autofocus"
      :id="id"
      :required="required"
      type="password"
      :placeholder="placeholder"
      v-bind="$attrs"
      v-on="inputListeners"
    >

    <mui-data-element-password
      :icon="icon"
      :text="value"
      :placeholder="placeholder"
      slot="read"
    />
  </mui-field>
</template>

<script>
import field from '@/mixins/Field';

export default {
  mixins: [field],
};
</script>
