<template>
  <mui-field-select
    :options="times"
    labelled-by="time"
    tracked-by="time"
    :return-object="false"
    placeholder="Uhrzeit"
    searchable
    v-bind="$props"
    v-on="$listeners"
    v-model="$attrs.value"
  />
</template>

<script>
import Field from '@/mixins/Field';

import timesutil from '@/helpers/times';

export default {
  mixins: [Field],

  props: {
    min: {
      type: String,
      default: '00:00',
    },

    max: {
      type: String,
      default: '23:59',
    },

    stepsPerHour: {
      type: Number,
      default: 4,
    },
  },

  computed: {
    times() {
      const from = timesutil.toTime(this.min);
      const fromMinutes = timesutil.toMinutes(from);
      const until = timesutil.toTime(this.max);
      const untilMinutes = timesutil.toMinutes(until);

      const hours = until[0] - from[0];

      const times = [];

      for (let i = 0; i <= hours; i += 1) {
        const timeHour = from[0] + i;

        this.steps.forEach((timeMinutes) => {
          const timeInMinutes = timesutil.toMinutes(`${timeHour}:${timeMinutes}`);

          if (timeInMinutes <= untilMinutes && timeInMinutes >= fromMinutes) {
            const time = timesutil.toString(timeInMinutes);
            times.push({ time });
          }
        });
      }

      return times;
    },

    steps() {
      const minutesPerStep = 60 / this.stepsPerHour;
      const steps = [];

      for (let n = 0; n < this.stepsPerHour; n += 1) {
        steps.push(n * minutesPerStep);
      }

      return steps;
    },
  },
};
</script>
