<template>
  <mui-field
    :label="value.title"
    :info="info"
    :has-error="hasError"
    :has-warning="hasWarning"
    :edit="isEditing"
    :editable="editable"
    :readable="readable"
    :pinnable="pinnable"
    :pinned="pinned"
    :removable="removable"
    :show-field-label-on-edit="false"
    @save="onSave"
    @pin="onPin"
    @remove="onRemove"
  >
    <template slot="edit">
      <mui-grid>
        <mui-col width="2/3">
          <mui-input label="E-Mail-Adresse" icon="envelope">
            <input
              class="mui-input-element"
              placeholder="dieterrams@braun.de"
              v-model="value.email"
              type="email"
              autofocus
            >
          </mui-input>
        </mui-col>

        <mui-col width="1/3">
          <mui-input label="Bezeichnung">
            <input
              class="mui-input-element"
              placeholder="Bezeichnung"
              v-model="value.title"
              list="defaultTitles-emails"
            >

            <datalist id="defaultTitles-emails">
              <option value="Privat" />
              <option value="Geschäftlich" />
              <option value="Sonstige" />
            </datalist>
          </mui-input>
        </mui-col>
      </mui-grid>
    </template>

    <mui-data-element-email
      placeholder="dieterrams@braun.de"
      :email="value.email"
      slot="read"
    />
  </mui-field>
</template>

<script>
import field from '@/mixins/Field';

export default {
  mixins: [field],
};
</script>
