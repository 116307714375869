<template>
  <mui-button
    :icon="icon"
    theme="ghost-highlight-static"
    v-if="views.length <= 1 && activeView"
  >
    {{ activeView.title }}
  </mui-button>

  <mui-dropdown v-else-if="activeView">
    <mui-button
      :icon="icon"
      theme="ghost-highlight"
      @click="$refs.selectView.toggle()"
    >
      {{ activeView.title }}
    </mui-button>

    <mui-dropdown-content align="left" size="medium" ref="selectView">
      <mui-button-group direction="vertical">
        <mui-button
          v-for="(view, key) in views"
          :key="key"
          theme="nav"
          :class="{'-is-active': view.id === activeView.id }"
          @click="selectView(view)"
        >
          {{ view.title }}
        </mui-button>
      </mui-button-group>
    </mui-dropdown-content>
  </mui-dropdown>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: null,
    },
    activeView: {
      type: Object,
      default: null,
    },
    views: {
      type: Array,
      default: null,
    },
  },

  methods: {
    selectView(view) {
      this.$emit('select-view', view);
      this.$refs.selectView.close();
    },
  },
};
</script>
