<template>
  <span class="-formatted-name">
    <template v-if="person.is_organization">
      <component :is="emphasize ? 'strong' : 'span'">{{ person.organization }}</component>
    </template>
    <template v-else-if="address">
      <template v-if="person.prefix">{{ person.prefix }}</template>
      {{ person.firstname }}
      <component :is="emphasize ? 'strong' : 'span'">{{ person.name }}</component>
    </template>
    <template v-else>
      <template v-if="person.prefix">{{ person.prefix }}</template>
      <component :is="emphasize ? 'strong' : 'span'">{{ person.name }}</component>,
      {{ person.firstname }}
    </template>
  </span>
</template>

<script>
export default {
  props: {
    person: {
      type: Object,
      default: null,
    }, // { prefix, firstname, name, organization, is_organization },

    address: {
      type: Boolean,
      default: false,
    },

    emphasize: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
