export default {
  computed: {
    dialog() {
      return this.$refs.dialog;
    },
  },

  methods: {
    resetDialogData() {
      Object.assign(this.$data, this.$options.data());
    },
  },
};
