/* eslint-disable no-underscore-dangle */
import {
  format,
  formatRelative,
  isDate,
  differenceInYears,
  parseISO,
  isToday,
  isTomorrow,
  isYesterday,
} from 'date-fns';
import de from 'date-fns/locale/de';

const DATE_OPTIONS = {
  locale: de,
  weekStartsOn: 1,
};

/// Format: Wed Dec 09 2020 00:00:00 GMT+0100 (Mitteleuropäische Normalzeit)
const toDate = (dateStr) => {
  if (!dateStr) return null;
  if (isDate(dateStr)) return dateStr;
  if (typeof dateStr !== 'string') return null;

  return parseISO(dateStr);
};

/// Format (default): 9. Dez. 2020
const toFormat = (date, formatStr = 'PP') => {
  const dateObj = toDate(date);
  if (!isDate(dateObj)) return null;

  return format(dateObj, formatStr, DATE_OPTIONS);
};

/// Format: 2020-12-09
const toSqlDate = (date) => toFormat(date, 'yyyy-MM-dd');

/// Format: 09.12.2020
const toDateString = (date) => toFormat(date, 'P');

/// Format: Mi. 9. Dez. 2020
const toShortString = (date) => toFormat(date, 'EEEEEE. do MMM. yyyy');

/// Format: Mi, 09. Dez. 2020
const toMediumString = (date) => toFormat(date, 'EEEEEE, dd. MMM. yyyy');

/// Format: 9. Dezember 2020
const toLongString = (date, withTime) => {
  let formatString = 'do MMMM yyyy';
  if (withTime) {
    formatString += ' HH:mm';
  }
  return toFormat(date, formatString);
};

/// Format: Mittwoch, 9. Dezember 2020
const toFullString = (date, withTime) => {
  let formatString = 'EEEE, do MMMM yyyy';
  if (withTime) {
    formatString += ', HH:mm';
  }
  return toFormat(date, formatString);
};

/// Format: Heute, Mi. 09.12.
const toTitleString = (dateParameter) => {
  const dateString = toFormat(dateParameter, 'EEEEEE. dd.MM.');
  let prefix = '';

  if (isToday(dateParameter)) {
    prefix = 'Heute, ';
  } else if (isTomorrow(dateParameter)) {
    prefix = 'Morgen, ';
  } else if (isYesterday(dateParameter)) {
    prefix = 'Gestern, ';
  }

  return prefix + dateString;
};

const toRelative = (date) => {
  const dateObj = toDate(date);
  if (!isDate(dateObj)) return null;

  return formatRelative(dateObj, new Date(), DATE_OPTIONS);
};

const toAge = (date) => {
  const birthday = toDate(date);
  if (!isDate(birthday)) return null;
  return differenceInYears(new Date(), birthday);
};

export default {
  toDate,
  toFormat,
  toAge,

  toSqlDate,
  toDateString,
  toShortString,
  toMediumString,
  toLongString,
  toFullString,
  toRelative,
  toTitleString,

  DATE_OPTIONS,
};
