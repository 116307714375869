<template>
  <mui-event
    :title="title"
    :info="info"
    :meta="meta"
    :color="event.color"
    :status="event.status"
    @click="goToEvent"
  />
</template>

<script>
import datefns from '@/helpers/datefns';
import times from '@/helpers/times';

export default {
  props: {
    event: {
      type: Object,
      default: null,
    },
  },

  computed: {
    isWholeDay() {
      return (!this.event.start_time || !this.event.end_time);
    },

    title() {
      if (this.isWholeDay) {
        return null;
      }

      return this.formattedDate;
    },

    info() {
      if (this.isWholeDay) {
        return this.formattedDate;
      }

      return this.formattedTime;
    },

    meta() {
      if (this.event.status === 'weather') {
        return null;
      }

      if (this.event.employees && this.event.employees.length) {
        let prefix = this.event.employees[0].firstname.charAt(0) + this.event.employees[0].name.charAt(0);

        if (this.event.employees.length > 1 || this.event.horses.length > 1) {
          prefix += '+';
        }

        return prefix.toUpperCase();
      }

      return null;
    },

    formattedDate() {
      return datefns.toLongString(this.event.start_date);
    },

    formattedTime() {
      const start = times.toString(times.toMinutes(this.event.start_time));
      const end = times.toString(times.toMinutes(this.event.end_time));

      return `${start} – ${end} Uhr`;
    },
  },

  methods: {
    goToEvent() {
      this.$router.push({
        name: 'calendar',
        params: {
          date: datefns.toSqlDate(this.event.start_date),
          eventId: this.event.id,
        },
      });
    },
  },
};
</script>
