<template>
  <mui-field-select
    class="mui-field-colors"
    :options="colors"
    :required="required"
    labelled-by="title"
    :tracked-by="trackedBy"
    :return-object="returnObject"
    placeholder="Farbe auswählen"
    searchable
    v-bind="$props"
    v-on="$listeners"
    v-model="$attrs.value"
  >
    <template slot="selection" slot-scope="{selection}">
      <mui-event :color="selection.class" :title="selection.title" />
    </template>

    <template slot="option" slot-scope="{option, selectOption, isSelected}">
      <mui-event
        :color="option.class"
        :title="isSelected(option) ? `✓ ${option.title}` : option.title"
        @click.prevent="selectOption(option)"
      />
    </template>

    <template slot="read" slot-scope="{selectedOption, placeholder}">
      <mui-data-element-text v-if="!selectedOption" :placeholder="placeholder" />
      <mui-event v-else :color="selectedOption.class" :title="selectedOption.title" />
    </template>
  </mui-field-select>
</template>

<script>
import Field from '@/mixins/Field';
import config from '@/config';

export default {
  mixins: [Field],

  props: {
    trackedBy: {
      type: String,
      default: 'class',
    },

    returnObject: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      colors: config.colors,
    };
  },
};
</script>

<style>
.mui-field-colors .mui-select-element {
  padding: 0;
  padding-right: 3.25rem;
}

.mui-field-colors .mui-select-search,
.mui-field-colors .mui-select-placeholder {
  padding-left: .75rem;
  padding-top: .75rem;
  padding-bottom: .75rem;
}
</style>
