import { get, post } from '@/utils/api';

const TRESHOLD_SCROLL_BOTTOM = 20;

export default {
  data() {
    return {
      endpoint: null,
      paginate: false,

      sort: null,
      collection: [],

      queryParameters: {},
      response: null,
      isLoading: false,

      isLoadingNext: false,
      next: false,
    };
  },

  computed: {
    parametersToQueryData() {
      return this.queryParameters;
    },
  },

  methods: {
    get,
    post,

    async fetchData(url) {
      const endpoint = url || this.endpoint;
      const response = await get(endpoint, this.parametersToQueryData);
      return response;
    },

    async loadData() {
      let response;

      if (this.isLoading) return;

      this.$store.dispatch('system/startLoading');
      this.isLoading = true;
      try {
        response = await this.fetchData();
        this.setCollectionData(response.data.data);

        if (this.paginate && response.data.links.next) {
          this.next = response.data.links.next;
        } else {
          this.next = null;
        }
      } catch (e) {
        response = e.response;
        this.$store.dispatch('toasts/push', {
          type: 'error',
          title: 'Abrufen der Daten nicht möglich',
          text: 'Bitte versuche es erneut oder kontaktiere deinen Administrator.',
          // eslint-disable-next-line no-underscore-dangle
          id: `from-${this._uid}`,
        });
        console.log(e);
      }
      this.isLoading = false;
      this.$store.dispatch('system/finishLoading');

      this.response = response;
    },

    async loadNextData() {
      let response;

      if (this.isLoadingNext || !this.next) return;

      this.$store.dispatch('system/startLoading');
      this.isLoadingNext = true;
      try {
        response = await get(this.next);
        this.appendToCollectionData(response.data.data);

        if (this.paginate && response.data.links.next) {
          this.next = response.data.links.next;
        } else {
          this.next = null;
        }
      } catch (e) {
        response = e.response;
      }
      this.isLoadingNext = false;
      this.$store.dispatch('system/finishLoading');

      this.response = response;
    },

    setCollectionData(data) {
      this.collection = data;
      this.afterDataLoaded();
    },

    appendToCollectionData(data) {
      this.collection = this.collection.concat(data);
    },

    afterDataLoaded() {
      // can be overwritten by views
    },

    sortBy(column) {
      let newOrder = column.key;
      let order = 'asc';

      if (this.sort === newOrder) {
        newOrder = `-${newOrder}`;
        order = 'desc';
      }

      const currentSortedColumn = this.columns.find((col) => col.sortedBy);
      if (currentSortedColumn) {
        this.columns.find((col) => col.sortedBy).sortedBy = false;
      }

      const newSortedColumn = this.columns.find((col) => col.key === column.key);
      if (newSortedColumn) {
        this.columns.find((col) => col.key === column.key).sortedBy = order;
      }

      this.sort = newOrder;
    },

    initCollection() {
      if (!this.endpoint) return;
      this.loadData();
    },

    initBottomScroll() {
      this.$events.$on('scroll', this.handleBottomScroll);
    },

    destroyBottomScroll() {
      this.$events.$off('scroll', this.handleBottomScroll);
    },

    handleBottomScroll() {
      const scrollPosition = document.documentElement.scrollTop;
      const totalHeight = document.getElementById('app').offsetHeight - TRESHOLD_SCROLL_BOTTOM;
      const bottomOfWindow = (scrollPosition + window.innerHeight) >= totalHeight;

      if (bottomOfWindow) {
        this.loadNextData();
      }
    },
  },

  watch: {
    sort() {
      this.queryParameters.sort = this.sort;
      this.loadData();
    },
  },
};
