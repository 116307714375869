<template>
  <mui-tile
    :subline="subline ? subline : `Nr. ${person.id}`"
    :icon="type === 'horse' ? icon : null"
    ref="tile"
    @open="loadRemoteData"
    expandable
  >
    <mui-icon
      slot="icon"
      type="circle"
      :class="`color-${iconColor}`"
    />

    <strong slot="title">
      <mui-strings-name address :emphasize="false" :person="person" />
    </strong>

    <template v-if="hasRemoteContent">
      <mui-loader v-if="isLoading" />
      <template v-else>
        <mui-button-group direction="vertical" v-if="resource && resource.favourite_phones">
          <mui-data-element-phone
            v-for="phone in resource.favourite_phones"
            :key="phone.id"
            in-dropdown
            :number="phone.phone"
            :label="phone.title"
          />
        </mui-button-group>
        <mui-button-group direction="vertical">
          <mui-button
            icon="arrow-right"
            :link="{ name: profileLink, params: { id: person.id } }"
          >
            Profil ansehen
          </mui-button>
        </mui-button-group>
      </template>
    </template>

    <mui-button-group direction="vertical" v-else>
      <mui-button
        icon="arrow-right"
        :link="{ name: profileLink, params: { id: person.id } }"
      >
        Profil ansehen
      </mui-button>
    </mui-button-group>
  </mui-tile>
</template>

<script>
import {
  get,
} from '@/utils/api';

export default {
  props: {
    person: {
      type: Object,
      default: null,
    },

    type: {
      type: String,
      default: null,
    },

    subline: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      response: null,
      resource: null,
      isLoading: false,
    };
  },

  computed: {
    icon() {
      switch (this.type) {
        case 'user':
        case 'customer':
        case 'employee':
          return 'user';

        case 'horse':
          return 'horse-head';

        default:
          return null;
      }
    },

    iconColor() {
      if (this.person && this.person.color) {
        return this.person.color[0].class;
      }

      return 'blue-base';
    },

    profileLink() {
      return `${this.type}-overview`;
    },

    hasRemoteContent() {
      const typesWithRemoteContent = ['customer', 'employee'];

      return typesWithRemoteContent.includes(this.type);
    },

    endpoint() {
      return `${this.type}s/${this.person.id}`;
    },
  },

  methods: {
    loadRemoteData() {
      if (!this.resource) {
        this.loadData();
      }
    },

    async loadData() {
      let response;

      if (this.isLoading) return;

      const fields = {};
      fields[`${this.type}s`] = 'id';

      this.isLoading = true;
      try {
        response = await get(this.endpoint, {
          fields,
          include: 'favouritePhones',
        });
        this.resource = response.data.data;
      } catch (e) {
        response = e.response;
      }

      this.response = response;
      this.isLoading = false;

      this.$nextTick(() => {
        this.$refs.tile.resetContentHeight();
        this.$refs.tile.setContentHeight();
      });
    },
  },
};
</script>
