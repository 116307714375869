<template>
  <mui-search
    @search="onSearch"
    @select-result="onSelectResult"
    @reset="onReset"
    :results="preparedSearchResults"
    :is-searching="isSearching"
    ref="search"
  />
</template>

<script>
import { post } from '@/utils/api';
import debounce from '@/helpers/debounce';
import datefns from '@/helpers/datefns';

export default {
  data() {
    return {
      searchResults: [],
      isSearching: false,
    };
  },

  computed: {
    preparedSearchResults() {
      return this.searchResults.map((result) => {
        let info = null;
        let id = null;
        let details = null;

        if (result.searchable) {
          info = `Nr. ${result.searchable.id}`;
          id = result.searchable.id;
        }

        if (result.type === 'agreements') {
          info = result.searchable.uid;
        }
        if (result.type === 'invoices') {
          info = `vom ${datefns.toDateString(result.searchable.date)}`;
        }
        if (result.type === 'events') {
          details = result.searchable;
        }

        return {
          type: result.type,
          title: result.title,
          url: result.url,
          id,
          info,
          details,
        };
      });
    },
  },

  methods: {
    onSelectResult(result) {
      if (result.url) {
        this.$router.push(result.url);
      }

      let routeName;
      let params;

      switch (result.type) {
        case 'customers':
          routeName = 'customer-overview';
          break;
        case 'employees':
          routeName = 'employee-overview';
          break;
        case 'contacts':
          routeName = 'contact-overview';
          break;
        case 'horses':
          routeName = 'horse-overview';
          break;
        case 'agreements':
          routeName = 'agreement-details';
          break;
        case 'invoices':
          routeName = 'invoice-details';
          break;
        case 'events':
          routeName = 'calendar';
          params = { date: result.details.start_date, eventId: result.id };
          break;

        default:
          break;
      }

      if (routeName) {
        this.$router.push({
          name: routeName,
          params: { ...params, id: result.id },
        });
      }
    },

    async onSearch(query) {
      this.isSearching = true;
      await this.search(query);
    },

    onReset() {
      this.searchResults = [];
      this.isSearching = false;
    },

    // eslint-disable-next-line prefer-arrow-callback
    // eslint-disable-next-line func-names
    search: debounce(async function (query) {
      if (!query) {
        this.searchResults = [];
        this.isSearching = false;
        return;
      }

      const results = await post('search', { query });
      this.searchResults = results.data;

      this.addCustomResults(query);

      this.isSearching = false;
    }, 250),

    addCustomResults(query) {
      const dateRegex = /^(\d{2})([./-])(\d{2})\2(\d{4})$/;

      if (query.match(dateRegex)) {
        const dateForUrl = query.replace(dateRegex, '$4-$3-$1');

        this.searchResults.push({
          type: 'calendar',
          title: query,
          url: `/calendar/${dateForUrl}`,
        });
      }
    },

    bindShortcuts(event) {
      switch (event.key) {
        case '/':
          event.preventDefault();
          this.$refs.search.$el.querySelector('input').focus();
          break;
        case 'Escape':
          event.preventDefault();
          this.$refs.search.$el.querySelector('input').blur();
          break;
        default:
          break;
      }
    },
  },

  // mounted() {
  //   this.$events.$on('keydown', this.bindShortcuts);
  // },

  // destroyed() {
  //   this.$events.$off('keydown', this.bindShortcuts);
  // },
};
</script>
