<template>
  <mui-loader v-if="isLoading" />
  <mui-field-select
    v-else
    :options="collection"
    :required="required"
    labelled-by="fullname"
    tracked-by="id"
    described-by="id"
    placeholder="Kunden auswählen"
    searchable
    search-only
    :search-through="['id', 'name', 'firstname', 'organization', 'prefix']"
    :search-after="2"
    :multiple="multiple"
    :taggable="multiple"
    v-bind="$props"
    v-on="$listeners"
    v-model="$attrs.value"
  >
    <template slot="selection" slot-scope="{ selection, remove }" v-if="multiple">
      <span class="mui-select-selection">
        <mui-tag
          v-for="person in selection"
          :key="person.id"
          removeable
          @remove="remove(person)"
        >
          <mui-strings-name :person="person" />
        </mui-tag>
      </span>
    </template>

    <template slot="option" slot-scope="{ option, selectOption, isSelected }">
      <mui-button
        :class="{
          'mui-select-option': true,
          '-is-active': isSelected(option)
        }"
        @click.prevent="selectOption(option)"
      >
        <mui-strings-name :person="option" />
        <span class="mui-select-option-description">
          Nr. {{ option.id }}
        </span>
      </mui-button>
    </template>
  </mui-field-select>
</template>

<script>
import IsCollection from '@/mixins/IsCollection';
import Field from '@/mixins/Field';

export default {
  mixins: [IsCollection, Field],

  props: {
    multiple: {
      type: Boolean,
      default: true,
    },

    include: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      endpoint: '/customers',

      queryParameters: {
        append: 'fullname',
        include: this.include,
        fields: {
          customers: 'id,name,firstname,organization,is_organization',
        },
      },
    };
  },

  mounted() {
    this.initCollection();
  },
};
</script>
