import {
  differenceInMinutes,
  isAfter,
  parseISO,
} from 'date-fns';

import auth from '@/utils/auth';
import storage from '@/utils/storage';
import config from '@/config';

const storedValues = storage.get(config.storage.ACCESS_TOKEN);

export default {
  namespaced: true,

  state: {
    token: storedValues ? storedValues.access_token : null,
    user: storedValues ? storedValues.user : null,
    expires: storedValues ? storedValues.expires : null,
    routeAfterLogin: '/',
  },

  mutations: {
    success(state, response) {
      state.token = response.access_token;
      state.user = response.user;
      state.expires = response.expires;
    },

    reset(state) {
      state.token = null;
      state.user = null;
      state.expires = null;
    },

    routeAfterLogin(state, url) {
      state.routeAfterLogin = url;
    },
  },

  actions: {
    async login({ commit }, credentials) {
      try {
        const response = await auth.login(credentials);
        commit('success', response);
        return true;
      } catch (e) {
        return e.response.data;
      }
    },

    logout({ commit }) {
      auth.logout();
      storage.delete(config.storage.ACCESS_TOKEN);
      commit('reset');
    },

    async refresh({ commit }) {
      try {
        const response = await auth.refresh();
        commit('success', response);
        return true;
      } catch (e) {
        return e.response.data;
      }
    },

    setRouteAfterLogin({ commit }, url) {
      commit('routeAfterLogin', url);
    },
  },

  getters: {
    isLoggedIn: (state) => () => !!state.token && !!state.user,

    isAllowedTo: (state) => (permission) => {
      if (state.user.roles.includes('administrator')) {
        return true;
      }

      const userPermissions = state.user.permissions;

      if (typeof permission === 'string') {
        return userPermissions.some((p) => p === permission);
      }

      const hasPermissions = permission.filter((p) => userPermissions.includes(p));
      return hasPermissions.length > 0;
    },

    isShortlyExpiring: (state) => () => differenceInMinutes(parseISO(state.expires), new Date()) < config.storage.ACCESS_EXPIRY_TRESHOLD,

    isStillValid: (state) => () => isAfter(parseISO(state.expires), new Date()),
  },
};
