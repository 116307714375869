<template>
  <mui-sheet-inner padded>
    <mui-grid gutter="large">
      <mui-col>
        <mui-field-text
          label="Name der Ansicht"
          icon="text"
          placeholder="z.B. Alle Aktiven"
          v-model="settings.title"
          :readable="false"
          :edit="true"
        />
      </mui-col>
      <mui-col>
        <mui-input-checkbox
          v-model="settings.public"
          text="Ansicht für alle Benutzer sichtbar machen"
        />
      </mui-col>
    </mui-grid>
  </mui-sheet-inner>
</template>

<script>
export default {
  props: {
    settings: {
      type: Object,
      default: null,
    },
  },
};
</script>
