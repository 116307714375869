const toTime = (str) => {
  if (typeof str !== 'string') return str;

  const hours = str.split(':');
  return [parseInt(hours[0], 10), parseInt(hours[1], 10)];
};


const toTimeString = (time) => `${time[0].toString().padStart(2, '0')}:${time[1].toString().padStart(2, '0')}`;

const toString = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const mins = Math.floor(minutes - (hours * 60));

  return toTimeString([hours, mins]);
};


const toMinutes = (obj) => {
  if (!obj) return 0;

  const time = typeof obj === 'string' ? toTime(obj) : obj;

  let minutes = time[1];
  minutes += (time[0] * 60);

  return minutes;
};


const toHours = (obj) => {
  const minutes = toMinutes(obj);
  return minutes / 60;
};


const toDifference = (from, until) => {
  const fromAsTime = toTime(from);
  const untilAsTime = toTime(until);
  const difference = toMinutes(untilAsTime) - toMinutes(fromAsTime);

  return difference;
};


export default {
  toTime,
  toTimeString,
  toMinutes,
  toHours,
  toString,
  toDifference,
};
