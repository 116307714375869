var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mui-field-select',_vm._g(_vm._b({staticClass:"mui-field-colors",attrs:{"options":_vm.colors,"required":_vm.required,"labelled-by":"title","tracked-by":_vm.trackedBy,"return-object":_vm.returnObject,"placeholder":"Farbe auswählen","searchable":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var selection = ref.selection;
return [_c('mui-event',{attrs:{"color":selection.class,"title":selection.title}})]}},{key:"option",fn:function(ref){
var option = ref.option;
var selectOption = ref.selectOption;
var isSelected = ref.isSelected;
return [_c('mui-event',{attrs:{"color":option.class,"title":isSelected(option) ? ("✓ " + (option.title)) : option.title},on:{"click":function($event){$event.preventDefault();return selectOption(option)}}})]}},{key:"read",fn:function(ref){
var selectedOption = ref.selectedOption;
var placeholder = ref.placeholder;
return [(!selectedOption)?_c('mui-data-element-text',{attrs:{"placeholder":placeholder}}):_c('mui-event',{attrs:{"color":selectedOption.class,"title":selectedOption.title}})]}}]),model:{value:(_vm.$attrs.value),callback:function ($$v) {_vm.$set(_vm.$attrs, "value", $$v)},expression:"$attrs.value"}},'mui-field-select',_vm.$props,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }