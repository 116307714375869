<template>
  <mui-data-element
    :placeholder="!address"
    :label="label"
    :link="linkToMaps"
    v-bind="$props"
    v-on="$listeners"
  >
    <mui-strings-address :address="address" v-if="address" />
    <template v-else>
      {{ placeholder }}
    </template>
  </mui-data-element>
</template>

<script>
export default {
  props: {
    address: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },

  computed: {
    linkToMaps() {
      if (!this.address) return null;

      const rawAddress = `${this.address.street}, ${this.address.postcode} ${this.city}`;

      const url = `maps.google.com/maps/dir//${rawAddress.replace(' ', '+').replace('/', '%2F')}`;

      // if /* if we're on iOS, open in Apple Maps */
      // ((navigator.platform.indexOf('iPhone') != -1)
      //   || (navigator.platform.indexOf('iPad') != -1)
      //   || (navigator.platform.indexOf('iPod') != -1)) {
      //   return `maps://${url}`;
      // }

      return `https://${encodeURI(url)}`;
    },

    city() {
      if (this.address) {
        if (this.address.postcode) {
          const city = this.$store.getters['system/getCity'](this.address.postcode);

          if (city) {
            return city.name;
          }
        }
      }

      return null;
    },
  },
};
</script>
