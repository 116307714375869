<template>
  <mui-shield
    icon="stop-circle"
    color="green"
    :text="withText ? `Gilt bis ${toDateString(agreement.valid_until)}` : null"
    v-if="agreement.status === 'valid' && agreement.valid_until !== null"
  />
  <mui-shield
    icon="circle"
    color="green"
    :text="withText ? `Gilt seit ${toDateString(agreement.valid_from)}` : null"
    v-else-if="agreement.status === 'valid'"
  />
  <mui-shield
    icon="hourglass-start"
    color="blue"
    :text="withText ? `Gilt ab ${toDateString(agreement.valid_from)}` : null"
    v-else-if="agreement.status === 'notstarted'"
  />
  <mui-shield
    icon="signature"
    color="yellow"
    :text="withText ? 'Unterschrift fehlt' : null"
    v-else-if="agreement.status === 'notsigned'"
  />
  <mui-shield
    icon="stop-circle"
    :text="withText ? 'Abgelaufen' : null"
    v-else-if="agreement.status === 'ended'"
  />
  <mui-shield
    icon="pen"
    :text="withText ? 'Entwurf' : null"
    v-else-if="agreement.status === 'draft'"
  />
</template>

<script>
import datefns from '@/helpers/datefns';

export default {
  props: {
    agreement: {
      type: Object,
      required: true,
    },

    withText: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    toDateString: datefns.toDateString,
  },
};
</script>
