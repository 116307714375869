export default {
  props: {
    inheritResponse: Object,
  },

  data() {
    return {
      resource: {},
      resourceSent: {},
      response: Object,
    };
  },

  computed: {
    hasErrors() {
      return this.response && this.response.data && this.response.data.errors;
    },

    errors() {
      if (!this.hasErrors) return null;
      return this.response.data.errors;
    },
  },

  methods: {
    hasFieldErrors(fieldname) {
      if (this.hasErrors) {
        return !!this.errors[fieldname];
      }

      return false;
    },

    getFieldErrors(fieldname) {
      if (this.hasFieldErrors(fieldname)) {
        const errors = this.errors[fieldname].map((error) => error.detail);
        return errors.join(' ');
      }

      return null;
    },
  },

  watch: {
    resource: {
      handler() {
        Object.keys(this.resource).forEach((key) => {
          if (this.resource[key] !== this.resourceSent[key]) {
            if (this.hasFieldErrors(key)) {
              delete this.response.data.errors[key];
            }
          }
        });
      },
      deep: true,
    },

    inheritResponse: {
      handler() {
        this.response = this.inheritResponse;
      },
      deep: true,
    },
  },
};
