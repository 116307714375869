<template>
  <mui-sidebar
    ref="sidebar"
    id="sidebar"
    :class="{
      '-is-visible': isVisibleOnMobile
    }"
  >
    <mui-navigation>
      <mui-button
        theme="nav"
        :link="{ name: 'dashboard' }"
        icon="home"
      >
        Übersicht
      </mui-button>
    </mui-navigation>

    <mui-navigation title="Termine" v-if="canViewAppointments()">
      <mui-button
        theme="nav"
        :link="{ name: 'calendar' }"
        icon="calendar"
      >
        Kalender
      </mui-button>
      <mui-button
        theme="nav"
        :link="{ name: 'reservations' }"
        icon="stream"
      >
        Warteliste
        <template slot="badges">
          <mui-badge
            size="tiny"
            v-for="(badge, key) in getBadgesFor('reservations')"
            :key="key"
            :type="badge.type"
            :text="badge.count"
          />
        </template>
      </mui-button>
      <!-- <mui-button
        theme="nav"
        :link="{ name: 'events' }"
        icon="business-time"
      >
        Termine
      </mui-button> -->
    </mui-navigation>

    <mui-navigation title="Personen" v-if="canViewResources()">
      <mui-button
        v-if="can('customers.index')"
        theme="nav"
        :link="{ name: 'customers' }"
        icon="users"
      >
        Kunden
      </mui-button>
      <mui-button
        v-if="can('employees.index')"
        theme="nav"
        :link="{ name: 'employees' }"
        icon="users-class"
      >
        Mitarbeiter
      </mui-button>
      <mui-button
        v-if="can('horses.index')"
        theme="nav"
        :link="{ name: 'horses' }"
        icon="horse-head"
      >
        Pferde
      </mui-button>
      <mui-button
        v-if="can('contacts.index')"
        theme="nav"
        :link="{ name: 'contacts' }"
        icon="id-card-alt"
      >
        Kontakte
      </mui-button>
    </mui-navigation>

    <mui-navigation title="Abrechnung" v-if="canViewBilling()">
      <mui-button
        v-if="can('agreements.index')"
        theme="nav"
        :link="{ name: 'agreements' }"
        icon="file-contract"
      >
        Vereinbarungen
        <template slot="badges">
          <mui-badge
            size="tiny"
            v-for="(badge, key) in getBadgesFor('agreements')"
            :key="key"
            :type="badge.type"
            :text="badge.count"
          />
        </template>
      </mui-button>
      <mui-button
        v-if="can('invoices.index')"
        theme="nav"
        :link="{ name: 'invoices' }"
        icon="file-invoice"
      >
        Rechnungen
        <template slot="badges">
          <mui-badge
            size="tiny"
            v-for="(badge, key) in getBadgesFor('invoices')"
            :key="key"
            :type="badge.type"
            :text="badge.count"
          />
        </template>
      </mui-button>
      <!-- <mui-button
        v-if="can('prescriptions.index')"
        theme="nav"
        :link="{ name: 'prescriptions' }"
        icon="file-spreadsheet"
      >
        Verordnungen
      </mui-button> -->
      <!-- <mui-button
        v-if="can('findings.index')"
        theme="nav"
        :link="{ name: 'findings' }"
        icon="file-medical-alt"
      >
        Befunde
      </mui-button> -->
    </mui-navigation>

    <mui-navigation title="Analyse" v-if="canViewAnalysis()">
      <!-- <mui-button
        v-if="can('reports.index')"
        theme="nav"
        :link="{ name: 'reports' }"
        icon="presentation"
      >
        Berichte
      </mui-button> -->
      <mui-button
        v-if="can('statistics.index')"
        theme="nav"
        :link="{ name: 'statistics' }"
        icon="analytics"
      >
        Statistik
      </mui-button>
    </mui-navigation>

    <mui-navigation :title="`Eingeloggt als ${user.name}`" v-if="$mq.breakpoint === 'mobile'">
      <mui-button
        v-if="can('users.index')"
        theme="nav"
        :link="{ name: 'users' }"
        icon="user-secret"
      >
        Benutzer
      </mui-button>
      <mui-button
        v-if="can('settings.index')"
        theme="nav"
        :link="{ name: 'settings' }"
        icon="cog"
      >
        Einstellungen
      </mui-button>
      <mui-button
        theme="nav"
        :link="{ name: 'account-account' }"
        icon="user-circle"
      >
        Konto
      </mui-button>
      <mui-button
        theme="nav"
        :link="{ name: 'logout' }"
        icon="sign-out"
      >
        Abmelden
      </mui-button>
    </mui-navigation>
  </mui-sidebar>
</template>

<script>
export default {
  props: {
    isSidebarVisible: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isVisibleOnMobile() {
      return this.$mq.breakpoint === 'mobile' && this.isSidebarVisible;
    },

    user() {
      return this.$store.state.auth.user;
    },
  },

  methods: {
    getBadgesFor(item) {
      const badges = this.$store.state.system.navigation;

      if (!badges || !badges[item]) {
        return [];
      }

      return badges[item];
    },

    can(permission) {
      if (!this.$store.state.auth.user) return false;
      return this.$store.getters['auth/isAllowedTo'](permission);
    },

    canViewResources() {
      return this.can('customers.index')
        || this.can('employees.index')
        || this.can('horses.index')
        || this.can('contacts.index');
    },

    canViewBilling() {
      return this.can('agreements.index')
        || this.can('invoices.index')
        || this.can('contracts.index')
        || this.can('prescriptions.index')
        || this.can('findings.index');
    },

    canViewAnalysis() {
      return this.can('billings.index')
        || this.can('statistics.index')
        || this.can('reports.index');
    },

    canViewAppointments() {
      return this.can('events.index')
        || this.can('reservations.index');
    },
  },

  mounted() {
    this.$store.dispatch('system/loadNavigation');
  },
};
</script>
