<template>
  <mui-sheet style="overflow:hidden;">
    <div class="mui-welcome-wrapper">
      <div class="mui-welcome-text">
        <hgroup>
          <mui-headline tag="h1" size="largest" class="mt-4 mb-4">
            {{ randomGreeting }}, <strong>{{ user.name }}</strong>.
          </mui-headline>
          <mui-headline tag="h3" size="largest-sub" class="mb-2">
            Heute ist {{ formatDate(new Date(), true) }} Uhr.
          </mui-headline>
        </hgroup>
      </div>
      <figure class="mui-welcome-figure">
        <img src="../../../img/welcome.svg">
      </figure>
    </div>
  </mui-sheet>
</template>

<script>
import datefns from '@/helpers/datefns';
import { random } from '@/helpers/numbers';
import {
  getHours,
  getMonth,
  getDate,
} from 'date-fns';

export default {
  computed: {
    user() {
      return this.$store.state.auth.user;
    },

    isNight() {
      const hours = getHours(new Date());
      return hours < 5;
    },

    isEarly() {
      const hours = getHours(new Date());
      return hours < 10;
    },
    isLate() {
      const hours = getHours(new Date());
      return hours >= 19;
    },
    isNoon() {
      const hours = getHours(new Date());
      return hours >= 12 && hours < 14;
    },
    isCarnival() {
      const month = getMonth(new Date()) + 1;
      const day = getDate(new Date());

      return (month === 2 || month === 3)
        || (month === 11 && day === 11);
    },

    randomGreeting() {
      if (this.isNight) {
        return 'Geh’ schlafen';
      }
      if (this.isEarly) {
        return 'Guten Morgen';
      }
      if (this.isLate) {
        return 'Guten Abend';
      }

      const greetings = [
        'Willkommen',
        'Sei gegrüßt',
        'Hallo',
        'Guten Tag',
        'Tag',
        'Servus',
        'Moin',
        'Moin Moin',
        'Grüezi',
        'Grüß Gott',
        'Glückauf',
        'Namasté',
        'Ciao',
        'Ahoi',
        'Salut',
        'Hey',
        'Hi',
      ];

      if (this.isNoon) {
        greetings.push('Mahlzeit');
      }

      if (this.isCarnival) {
        greetings.push('Alaaf');
      }

      return greetings[random(0, greetings.length - 1)];
    },
  },

  methods: {
    formatDate: datefns.toFullString,
  },
};
</script>

<style lang="scss">
.mui-welcome-wrapper {
  overflow: hidden;
  position: relative;

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
}

.mui-welcome-text {
  position: absolute;
  height: 100%;
  max-width: 40vw;
  min-width: 20em;
  padding: 2rem;

  display: flex;
  align-items: center;

  @media screen and (max-width: 600px) {
    max-width: none;
    width: 100%;
    text-align: center;
    background: rgba(#fff, .9);

    hgroup {
      width: 100%;
    }
  }
}

$welcome-figure-width: 70%;

.mui-welcome-figure {
  height: 15rem;
  width: $welcome-figure-width;
  overflow: hidden;
  margin-left: auto;

  @media screen and (max-width: 600px) {
    width: 100%;
    height: 9rem;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center right;

    @media screen and (max-width: 960px) {
      object-fit: contain;
    }

    @media screen and (max-width: 600px) {
      object-fit: cover;
    }
  }
}
</style>
