import {
  addMinutes,
  isAfter,
  parseISO,
  formatISO,
} from 'date-fns';

const storage = {
  get(storageName) {
    const store = JSON.parse(localStorage.getItem(storageName));

    if (store) {
      if (store.expires && !isAfter(parseISO(store.expires), new Date())) {
        localStorage.removeItem(storageName);
        return null;
      }

      return store.data;
    }

    return false;
  },

  set(storageName, storageData, expiresIn) {
    const rawData = {
      data: storageData,
    };

    if (expiresIn && typeof expiresIn === 'number') {
      rawData.expires = formatISO(addMinutes(new Date(), expiresIn));
    }

    const data = JSON.stringify(rawData);
    localStorage.setItem(storageName, data);
  },

  delete(storageName) {
    localStorage.removeItem(storageName);
  },
};

export default storage;
