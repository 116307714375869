var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mui-field',{attrs:{"label":_vm.label,"icon":_vm.icon,"info":_vm.info,"has-error":_vm.hasError,"has-warning":_vm.hasWarning,"id":_vm.id,"required":_vm.required,"edit":_vm.edit,"editable":_vm.editable,"readable":_vm.readable,"pinnable":_vm.pinnable,"removable":_vm.removable},on:{"save":_vm.onSave,"pin":_vm.onPin,"remove":_vm.onRemove}},[_c('mui-input-select',{attrs:{"searchable":_vm.searchable,"multiple":_vm.multiple,"addable":_vm.addable,"taggable":_vm.taggable,"search-through":_vm.searchThrough,"search-only":_vm.searchOnly,"search-after":_vm.searchAfter,"tracked-by":_vm.trackedBy,"labelled-by":_vm.labelledBy,"described-by":_vm.describedBy,"grouped-by":_vm.groupedBy,"options":_vm.options,"placeholder":_vm.placeholder,"required":_vm.required,"disabled":_vm.disabled},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var selection = ref.selection;
var remove = ref.remove;
return [_vm._t("selection",null,{"selection":selection,"remove":remove})]}},{key:"option",fn:function(ref){
var option = ref.option;
var isSelected = ref.isSelected;
var selectOption = ref.selectOption;
return [_vm._t("option",null,{"option":option,"isSelected":isSelected,"selectOption":selectOption})]}}],null,true),model:{value:(_vm.selectedOption),callback:function ($$v) {_vm.selectedOption=$$v},expression:"selectedOption"}}),_vm._v(" "),_c('mui-data-element-text',{attrs:{"slot":"read","icon":_vm.icon,"text":_vm.selectedLabel,"placeholder":_vm.placeholder},slot:"read"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }