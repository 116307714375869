/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */

import store from './store';

export default {
  install(Vue) {
    Vue.config.productionTip = false;

    if (process.env.NODE_ENV !== 'production') {
      Vue.config.errorHandler = (err, vm) => {
        store.dispatch('toasts/push', {
          type: 'error',
          title: `Error catched in\`${vm.$options._componentTag}\``,
          text: err.message,
          lifetime: 10,
        });

        console.error(err);
      };
    }
  },
};
