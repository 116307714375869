<template>
  <mui-sheet>
    <mui-toolbar slot="header">
      <mui-button-group slot="left">
        <mui-button icon="sticky-note" theme="ghost-highlight-static">
          Merkzettel
        </mui-button>
      </mui-button-group>

      <mui-button-group slot="right">
        <mui-button icon="trash" @click="deleteMemo" />
      </mui-button-group>
    </mui-toolbar>

    <mui-loader v-if="!memoIsLoaded" />
    <div v-else class="p-2">
      <mui-memo
        ref="memo"
        v-model="memo"
        @save="saveMemo"
      />
    </div>
  </mui-sheet>
</template>

<script>
import { get, put } from '@/utils/api';

export default {
  data() {
    return {
      memo: null,
      memoIsLoaded: false,
      isLocked: false,
      isLoading: false,
    };
  },

  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },

  methods: {
    deleteMemo() {
      this.memo = '';
    },

    async loadMemo() {
      this.$store.dispatch('system/startLoading');

      try {
        const response = await get(`/users/${this.user.id}/memo`);
        this.memo = response.data.data.content;
        this.memoIsLoaded = true;
        this.$store.dispatch('system/finishLoading');
      } catch (e) {
        this.$store.dispatch('system/finishLoading', 'error');
      }
    },

    async saveMemo() {
      if (this.isLocked) return;

      this.isLocked = true;
      this.$store.dispatch('system/startLoading');

      const data = {
        content: this.memo,
      };

      try {
        await put(`/users/${this.user.id}/memo`, data);
        this.$store.dispatch('system/finishLoading', 'success');
      } catch (e) {
        this.$store.dispatch('system/finishLoading', 'error');
      }

      this.isLocked = false;
    },

    saveMemoOnKeydown(event) {
      if (event.target.tagName === 'TEXTAREA') {
        this.saveMemo();
      }
    },
  },

  mounted() {
    this.loadMemo();
    this.$events.$on('key.save', this.saveMemoOnKeydown);
  },

  destroyed() {
    this.$events.$off('key.save', this.saveMemoOnKeydown);
  },
};
</script>
