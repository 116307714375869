<template>
  <mui-field
    :label="value.title"
    :info="info"
    :has-error="hasError"
    :has-warning="hasWarning"
    :edit="isEditing"
    :editable="editable"
    :readable="readable"
    :pinnable="pinnable"
    :pinned="pinned"
    :removable="removable"
    :show-field-label-on-edit="false"
    @save="onSave"
    @pin="onPin"
    @remove="onRemove"
  >
    <template slot="edit">
      <mui-grid>
        <mui-col width="2/3">
          <mui-label>Adresse</mui-label>

          <mui-input v-if="hasName">
            <input
              class="mui-input-element"
              placeholder="Name"
              v-model="value.name"
              type="text"
              :autofocus="autofocus"
            >
          </mui-input>

          <mui-input>
            <input
              class="mui-input-element"
              placeholder="Straße"
              v-model="value.street"
              type="text"
            >
          </mui-input>

          <mui-input v-if="hasAdditionalLine">
            <input
              class="mui-input-element"
              placeholder="Zusätzliche Zeile"
              v-model="value.additional"
              type="text"
            >
          </mui-input>

          <mui-input>
            <mui-grid gutter="none">
              <mui-col width="1/3">
                <input
                  class="mui-input-element"
                  placeholder="PLZ"
                  v-model="value.postcode"
                  type="text"
                  maxlength="5"
                >
              </mui-col>
              <mui-col width="2/3">
                <span class="mui-input-element -disabled">
                  {{ city }}
                </span>
              </mui-col>
            </mui-grid>
          </mui-input>

          <mui-button
            type="button"
            size="small"
            icon="plus"
            class="mt-2"
            v-if="!hasName"
            @click.prevent="showName = true"
          >
            Namensfeld anzeigen
          </mui-button>
          <mui-button
            type="button"
            size="small"
            icon="plus"
            v-if="!hasAdditionalLine"
            @click.prevent="showAdditionalLine = true"
          >
            Zusätzliche Zeile anzeigen
          </mui-button>
        </mui-col>

        <mui-col width="1/3">
          <mui-input label="Bezeichnung">
            <input
              class="mui-input-element"
              placeholder="Bezeichnung"
              v-model="value.title"
              list="defaultTitles-addresses"
            >

            <datalist id="defaultTitles-addresses">
              <option value="Zuhause" />
              <option value="Arbeit" />
              <option value="Zentrale" />
            </datalist>
          </mui-input>
        </mui-col>
      </mui-grid>
    </template>

    <mui-data-element-address
      placeholder="Adresse"
      :address="value"
      slot="read"
    />
  </mui-field>
</template>

<script>
import field from '@/mixins/Field';

export default {
  mixins: [field],

  data() {
    return {
      showName: false,
      showAdditionalLine: false,
    };
  },

  computed: {
    city() {
      if (this.value) {
        if (this.value.postcode) {
          const city = this.$store.getters['system/getCity'](this.value.postcode);

          if (city) {
            return city.name;
          }
        }
      }

      return '(PLZ eingeben)';
    },

    hasName() {
      return (this.value && this.value.name) || this.showName;
    },

    hasAdditionalLine() {
      return (this.value && this.value.additional) || this.showAdditionalLine;
    },
  },
};
</script>
