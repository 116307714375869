<template>
  <mui-field
    :label="label"
    :icon="icon"
    :info="info"
    :has-error="hasError"
    :has-warning="hasWarning"
    :id="id"
    :required="required"
    :edit="edit"
    :editable="editable"
    :readable="readable"
    :pinnable="pinnable"
    :removable="removable"
    @save="onSave"
    @pin="onPin"
    @remove="onRemove"
  >
    <mui-input-select
      :searchable="searchable"
      :multiple="multiple"
      :addable="addable"
      :taggable="taggable"
      :search-through="searchThrough"
      :search-only="searchOnly"
      :search-after="searchAfter"
      :tracked-by="trackedBy"
      :labelled-by="labelledBy"
      :described-by="describedBy"
      :grouped-by="groupedBy"
      :options="options"
      :placeholder="placeholder"
      :required="required"
      :disabled="disabled"
      v-model="selectedOption"
    >
      <template slot="selection" slot-scope="{ selection, remove }">
        <slot name="selection" :selection="selection" :remove="remove" />
      </template>

      <template slot="option" slot-scope="{ option, isSelected, selectOption }">
        <slot
          name="option"
          :option="option"
          :is-selected="isSelected"
          :select-option="selectOption"
        />
      </template>
    </mui-input-select>

    <mui-data-element-text
      :icon="icon"
      :text="selectedLabel"
      :placeholder="placeholder"
      slot="read"
    />
  </mui-field>
</template>

<script>
import field from '@/mixins/Field';

export default {
  mixins: [field],

  props: {
    options: {
      type: Array,
      default: null,
    },

    placeholder: {
      type: String,
      default: 'Bitte auswählen',
    },

    searchable: {
      type: Boolean,
      default: false,
    },

    searchThrough: {
      type: Array,
      default: null,
    },

    searchOnly: {
      type: Boolean,
      default: false,
    },

    searchAfter: {
      type: Number,
      default: 1,
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    addable: {
      type: Boolean,
      default: false,
    },

    taggable: {
      type: Boolean,
      default: false,
    },

    trackedBy: {
      type: String,
      default: 'value',
    },

    labelledBy: {
      type: String,
      default: 'label',
    },

    describedBy: {
      type: String,
      default: 'info',
    },

    groupedBy: {
      type: String,
      default: null,
    },

    returnObject: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      selectedOption: this.$attrs.value,
    };
  },

  computed: {
    selectedLabel() {
      if (!this.selectedOption) return null;

      if (
        !this.returnObject
        && (typeof this.selectedOption === 'string' || typeof this.selectedOption === 'number')
      ) {
        if (!this.multiple) {
          const value = this.options.find(
            (option) => option[this.trackedBy] === this.selectedOption,
          );
          if (value) {
            return value[this.labelledBy];
          }
        }
      }

      if (this.multiple) {
        const values = this.selectedOption.map((option) => option[this.labelledBy]);
        return values.join(', ');
      }

      return this.selectedOption[this.labelledBy];
    },
  },

  watch: {
    selectedOption() {
      const option = this.returnObject
        ? this.selectedOption
        : this.selectedOption[this.trackedBy];

      this.$emit('input', option);
    },

    value() {
      this.selectedOption = this.returnObject
        ? this.$attrs.value
        : this.options.find(
          (opt) => opt[this.trackedBy] === this.$attrs.value,
        );
    },
  },
};
</script>
