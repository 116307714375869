<template>
  <mui-data-element
    class="-with-tags"
    :placeholder="!tags || !tags.length"
    v-bind="$props"
    v-on="$listeners"
  >
    <template v-if="!tags || !tags.length">
      {{ placeholder }}
    </template>

    <template v-else>
      <mui-tag
        v-for="(tag, key) in tags"
        :key="key"
        :text="tag[labelledBy]"
      />
    </template>
  </mui-data-element>
</template>

<script>
export default {
  props: {
    tags: {
      type: Array,
      default: null,
    },

    labelledBy: {
      type: String,
      default: 'label',
    },

    icon: {
      type: String,
      default: null,
    },

    placeholder: {
      type: String,
      default: '',
    },
  },
};
</script>
