const REPETITION_FREQUENCIES = [
  { label: 'Täglich', value: 'DAILY', pattern: ['täglich', 'Tage'] },
  { label: 'Wöchentlich', value: 'WEEKLY', pattern: ['wöchentlich', 'Wochen'] },
  { label: 'Monatlich', value: 'MONTHLY', pattern: ['monatlich', 'Monate'] },
  { label: 'Jährlich', value: 'YEARLY', pattern: ['jährlich', 'Jahre'] },
];

export function frequencies() {
  return REPETITION_FREQUENCIES;
}

export function textpattern(rules = {}) {
  let interval = '';
  let frequency = '';
  let until = '';

  if (rules.interval && rules.interval > 1) {
    interval = `alle ${rules.interval}`;
  }

  if (rules.frequency) {
    const n = rules.interval === 1 ? 0 : 1;

    const frequencyPattern = REPETITION_FREQUENCIES.find((f) => {
      if (typeof rules.frequency === 'string') {
        return f.value === rules.frequency;
      }

      return f.value === rules.frequency.value;
    });

    frequency = frequencyPattern.pattern[n];
  }

  if (rules.occurences) {
    until = `für ${rules.occurences} Termine`;
  } else if (rules.end_date) {
    until = `bis einschließlich ${rules.end_date}`;
  }

  return `${interval} ${frequency} ${until}`;
}
