<template>
  <mui-dialog size="small" ref="dialog">
    <mui-toolbar slot="header">
      <mui-button-group slot="left">
        <mui-button icon="trash" theme="ghost-highlight-static">
          {{ title }}
        </mui-button>
      </mui-button-group>
    </mui-toolbar>

    <mui-sheet-inner padded>
      <p>{{ message }}</p>

      <mui-notification type="warning">
        <strong>Achtung!</strong>
        Diese Aktion löscht den Datensatz unwiderruflich. Trotzdem löschen?
      </mui-notification>
    </mui-sheet-inner>

    <template slot="footer">
      <mui-button theme="attention" icon="trash" @click="onSumbit">
        Löschen
      </mui-button>
      <mui-button theme="tertiary" @click="$refs.dialog.close()" class="ml-auto">
        Abbrechen
      </mui-button>
    </template>
  </mui-dialog>
</template>

<script>
import Dialog from '@/mixins/Dialog';

export default {
  mixins: [Dialog],

  props: {
    title: String,
    message: String,
  },

  methods: {
    onSumbit() {
      this.$emit('delete');
    },
  },
};
</script>
