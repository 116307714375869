<template>
  <mui-avatar :size="size" :initials="initials" />
</template>

<script>
export default {
  props: {
    resource: {
      type: Object,
      default: null,
    },

    type: {
      type: String,
      default: null,
    },

    size: {
      type: String,
      default: 'medium',
    },
  },

  computed: {
    initials() {
      if (this.type === 'employee') {
        return this.resource.initials;
      }

      return null;
    },

    avatarImage() {
      if (this.resource) {
        return this.avatarImageByResource;
      }

      return this.avatarImageByType;
    },

    avatarImageByResource() {
      // if (this.resource.is_organization) {
      //   return avatarImageOrganization;
      // }

      // if (this.resource.gender === 'female') {
      //   return avatarImageFemale;
      // }

      // if (this.resource.gender === 'male') {
      //   return avatarImageMale;
      // }

      return false;
    },

    avatarImageByType() {
      // if (this.type === 'organization') {
      //   return avatarImageOrganization;
      // }
      // if (this.type === 'female') {
      //   return avatarImageFemale;
      // }
      // if (this.type === 'male') {
      //   return avatarImageMale;
      // }
      // if (this.type === 'horse') {
      //   return avatarImageHorse;
      // }

      return false;
    },
  },
};
</script>
