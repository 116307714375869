<template>
  <div style="width:100%;height:100%">
    <mui-event
      :title="event.headline"
      :info="event.description"
      :meta="meta"
      :marked="isMarked"
      :status="event.status"
      :color="event.color"
      :multiline="isInsideTimetable"
      :size="size"
      @click.stop="$emit('click');"
      :class="classList"
    >
      <template slot="meta" v-if="!!meta">
        <mui-icon type="star" v-if="isMyEvent" />
        <mui-icon type="exclamation-triangle" v-else-if="hasWarning" />
        <mui-icon type="check" v-else-if="event.status === 'attended'" />
      </template>

      <mui-icon
        type="cloud-rain"
        slot="suffix"
        v-if="event.status === 'weather'"
      />
    </mui-event>
  </div>
</template>

<script>
import EventResource from '@/mixins/Resources/Event';

export default {
  mixins: [EventResource],

  props: {
    event: {
      type: Object,
      default: null,
    },

    size: {
      type: String,
      default: 'medium',
    },

    inside: {
      type: String,
      default: 'timeline',
    },

    isOpen: {
      type: Boolean,
      default: false,
    },

    isRemoved: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isInsideTimeline() {
      return this.inside === 'timeline';
    },

    isInsideTimetable() {
      return !this.isInsideTimeline;
    },

    meta() {
      if (this.isInsideTimeline) {
        if (this.size === 'small' || this.size === 'tiny') {
          return null;
        }
      }

      if (this.hasEmployees) {
        return this.employeesCode;
      }

      if (this.hasWarning) {
        return 'true';
      }

      return null;
    },

    classList() {
      return {
        '-is-open': this.isOpen,
        '-is-removed': this.event.is_removed,
      };
    },
  },

  mounted() {
    if (this.$route.params.eventId) {
      // eslint-disable-next-line eqeqeq
      if (this.$route.params.eventId == this.event.id) {
        this.$emit('click');
      }
    }
  },
};
</script>
