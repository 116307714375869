<template>
  <mui-data-element
    :placeholder="!text"
    :label="label"
    v-bind="$props"
    v-on="$listeners"
    class="-formatted-date"
  >
    {{ formattedDate || placeholder }}
  </mui-data-element>
</template>

<script>
import datefns from '@/helpers/datefns';

export default {
  props: {
    text: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    format: {
      type: String,
      default: 'short',
    },
  },

  computed: {
    formattedDate() {
      if (this.format === 'long') {
        return datefns.toLongString(this.text);
      }
      if (this.format === 'short') {
        return datefns.toShortString(this.text);
      }

      return datefns.toFormat(this.text);
    },
  },
};
</script>

<style>
.-formatted-date {
  font-variant-numeric: tabular-nums;
}
</style>
