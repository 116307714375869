<template>
  <mui-data-element
    :placeholder="!text"
    :label="label"
    v-bind="$props"
    v-on="$listeners"
    class="-formatted-date"
  >
    <mui-badge size="small" v-if="text" :text="age" />
    {{ formattedDate || placeholder }}
  </mui-data-element>
</template>

<script>
import datefns from '@/helpers/datefns';

export default {
  props: {
    text: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },

  computed: {
    formattedDate() {
      return datefns.toShortString(this.text);
    },

    age() {
      return `${datefns.toAge(this.text)} Jahre`;
    },
  },
};
</script>
