import Vue from 'vue';

import breakpoint from '@/setup/breakpoint';
import events from '@/setup/events';
import router from '@/setup/router';
import store from '@/setup/store';
import errors from '@/setup/errors';
import '@/setup/icons';
import '@/setup/components';
import '@/setup/polyfills';

import App from './App.vue';

Vue.use(breakpoint);
Vue.use(events);
Vue.use(errors);

/**
 * Create global vue instance.
 */
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
