<template>
  <mui-button icon="arrow-left" :link="{ name: route }">
    {{ title }}
  </mui-button>
</template>

<script>
export default {
  props: {
    route: {
      type: String,
      default: 'dashboard',
    },

    title: {
      type: String,
      default: null,
    },
  },
};
</script>
