<template>
  <span class="-formatted-phone">{{ numberForHumans(number) }}</span>
</template>

<script>
import phoneutils from '@/helpers/phone';

export default {
  props: {
    number: {
      type: String,
      default: null,
    },
  },

  methods: {
    numberForHumans(string) {
      return phoneutils.parse(string).formatNational();
    },

    numberAsLink(string) {
      return phoneutils.parse(string).getURI();
    },
  },
};
</script>
