<template>
  <mui-loader v-if="isLoading" />
  <mui-field-select
    v-else
    :options="collection"
    labelled-by="oneliner"
    tracked-by="id"
    described-by="title"
    placeholder="Adresse auswählen"
    :required="required"
    :search-through="['id', 'title', 'street', 'postcode', 'oneliner']"
    searchable
    :multiple="multiple"
    :return-object="returnObject"
    v-bind="$props"
    v-on="$listeners"
    v-model="$attrs.value"
  />
</template>

<script>
import IsCollection from '@/mixins/IsCollection';
import Field from '@/mixins/Field';

export default {
  mixins: [IsCollection, Field],

  props: {
    multiple: {
      type: Boolean,
      default: false,
    },

    returnObject: {
      type: Boolean,
      default: true,
    },

    withAll: {
      type: Boolean,
      default: false,
    },

    personId: {
      type: [String, Number],
      required: true,
    },

    personType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      endpoint: null,
    };
  },

  mounted() {
    this.endpoint = `/${this.personType}/${this.personId}/addresses`;

    this.initCollection();
  },
};
</script>
