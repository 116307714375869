<template>
  <mui-schedule-wrapper
    :groups="collection"
    :from="config.app.calendar_from"
    :until="config.app.calendar_until"
    type="timetable"
    visible-minutes-in-viewport="450"
  >
    <mui-sheet slot-scope="{ schedule }">
      <template slot="header">
        <mui-toolbar>
          <mui-button-group slot="left">
            <mui-button icon="arrow-left" @click="previousDate" :disabled="isLoading" />
          </mui-button-group>

          <mui-button-group slot="center">
            <mui-button icon="calendar" theme="ghost-highlight" @click="goToCalendar">
              {{ currentDateTitle }}
            </mui-button>
          </mui-button-group>

          <mui-button-group slot="right">
            <mui-button icon="arrow-right" @click="nextDate" :disabled="isLoading" />
          </mui-button-group>
        </mui-toolbar>
      </template>

      <mui-loader v-if="isLoading" />
      <mui-timetable
        v-else
        :with-header="false"
        :timeunits="schedule.timeunits"
        :column-width="schedule.sizePerGroup"
        :schedule-width="schedule.scheduleWidth"
        :scroll-width="schedule.scrollWidth"
        :size-per-timeunit="schedule.sizePerTimeunit"
        :size-per-minute="schedule.sizePerMinute"
        :groups="collection"
      >
        <template slot="event" slot-scope="{ event }">
          <mui-event
            :title="event.headline"
            :status="event.status"
            :color="event.color"
            @click="goToEvent(event)"
          />
        </template>
      </mui-timetable>
    </mui-sheet>
  </mui-schedule-wrapper>
</template>

<script>
import IsCollection from '@/mixins/IsCollection';

import config from '@/config';

import {
  add,
  sub,
  getHours,
} from 'date-fns';
import datefns from '@/helpers/datefns';

export default {
  mixins: [IsCollection],

  data() {
    return {
      endpoint: '/calendar',
      selectedDate: new Date(),

      queryParameters: {
        filter: {},
        include: 'service,customers,horses',
        append: 'duration,color,headline,description',
      },
    };
  },

  computed: {
//     showHours() {
//       return 5;
//     },
//
//     // use with `${String(hourFrom).padStart(2, 0)}:00`
//     hourFrom() {
//       const now = new Date();
//       const hourOfNow = getHours(now);
//
//       const startOfSetting = config.app.calendar_from;
//       const hourOfStartOfSetting = parseInt(startOfSetting.split(':')[0], 10);
//
//       const endOfSetting = config.app.calendar_until;
//       const hourOfEndOfSetting = parseInt(endOfSetting.split(':')[0], 10);
//
//       if (hourOfNow <= hourOfStartOfSetting) {
//         return hourOfStartOfSetting;
//       }
//
//       if (hourOfNow + this.showHours >= hourOfEndOfSetting) {
//         return hourOfEndOfSetting - this.showHours;
//       }
//
//       return hourOfNow;
//     },
//
//     // use with `${String(hourUntil).padStart(2, 0)}:00`
//     hourUntil() {
//       return this.hourFrom + this.showHours;
//     },

    user() {
      return this.$store.state.auth.user;
    },

    config() {
      return config;
    },

    parametersToQueryData() {
      const parameters = JSON.parse(JSON.stringify(this.queryParameters));
      parameters.filter.on = datefns.toSqlDate(this.selectedDate);
      parameters.filter.hasEmployees = this.user.is_employee;
      return parameters;
    },

    currentDateTitle() {
      return datefns.toTitleString(this.selectedDate);
    },
  },

  methods: {
    previousDate() {
      const newDate = sub(this.selectedDate, { days: 1 });
      this.changeDate(newDate);
    },

    nextDate() {
      const newDate = add(this.selectedDate, { days: 1 });
      this.changeDate(newDate);
    },

    changeDate(date) {
      this.selectedDate = date;
      this.loadData();
    },

    afterDataLoaded() {
      window.dispatchEvent(new Event('resize'));
    },

    goToCalendar() {
      this.$router.push({
        name: 'calendar',
        params: {
          date: datefns.toSqlDate(this.selectedDate),
        },
      });
    },

    goToEvent(event) {
      this.$router.push({
        name: 'calendar',
        params: {
          date: datefns.toSqlDate(this.selectedDate),
          eventId: event.id,
        },
      });
    },
  },

  mounted() {
    this.initCollection();
  },
};
</script>
