import {
  post, get,
} from '@/utils/api';

import Form from './Form';

export default {
  mixins: [Form],

  data() {
    return {
      detailsRoute: null,
      endpoint: null,

      resource: {},
      resourceSent: {},

      errorCodes: {},

      response: null,
      isLocked: false,
    };
  },

  methods: {
    get,
    post,

    afterResourceSaved(data) {
      if (this.detailsRoute) {
        this.dialog.close();
        this.$router.push(`${this.detailsRoute}/${data.id}`);
      } else {
        this.resetDialogData();
      }
    },

    async saveData(data) {
      const response = await post(this.endpoint, data);
      return response;
    },

    async storeResource() {
      if (this.isLocked) return;

      this.isLocked = true;
      this.$store.dispatch('system/startLoading');
      this.resourceSent = { ...this.resource };

      try {
        const { data } = await this.saveData(this.resource);
        this.$store.dispatch('system/finishLoading', 'success');
        this.afterResourceSaved(data);
      } catch (e) {
        this.response = e.response;
        this.$store.dispatch('system/finishLoading', 'error');
        this.$store.dispatch('toasts/push', {
          type: 'error',
          title: 'Speichern nicht möglich',
          text: 'Bitte überprüfe deine Angaben und versuche es erneut.',
          // eslint-disable-next-line no-underscore-dangle
          id: `from-${this._uid}`,
        });

        if (this.response.data.code && this.errorCodes[this.response.data.code]) {
          this.errorCodes[this.response.data.code](this);
        }
      }

      this.isLocked = false;
    },
  },
};
