import { lcfirst } from '../helpers/strings';

export default {
  install(Vue) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$events = new Vue({

      data() {
        return {
          entered: null,
        };
      },

      created() {
        window.addEventListener('scroll', this.scroll);
        window.addEventListener('online', this.online);
        window.addEventListener('offline', this.offline);
        window.addEventListener('dragenter', this.dragenter, false);
        window.addEventListener('dragover', this.prevent, false);
        window.addEventListener('dragexit', this.prevent, false);
        window.addEventListener('dragleave', this.dragleave, false);
        window.addEventListener('drop', this.drop, false);
        window.addEventListener('keydown', this.keydown, false);
        window.addEventListener('keyup', this.keyup, false);
        document.addEventListener('click', this.click, false);
      },

      destroyed() {
        window.removeEventListener('scroll', this.scroll);
        window.removeEventListener('online', this.online);
        window.removeEventListener('offline', this.offline);
        window.removeEventListener('dragenter', this.dragenter, false);
        window.removeEventListener('dragover', this.prevent, false);
        window.removeEventListener('dragexit', this.prevent, false);
        window.removeEventListener('dragleave', this.dragleave, false);
        window.removeEventListener('drop', this.drop, false);
        window.removeEventListener('keydown', this.keydown, false);
        window.removeEventListener('keyup', this.keyup, false);
        document.removeEventListener('click', this.click, false);
      },

      methods: {
        scroll(event) {
          this.$emit('scroll', event);
        },

        click(event) {
          this.$emit('click', event);
        },

        drop(event) {
          this.prevent(event);
          this.$emit('drop', event);
        },

        dragenter(event) {
          this.entered = event.target;
          this.prevent(event);

          this.$emit('dragenter', event);
        },

        dragleave(event) {
          this.prevent(event);

          if (this.entered === event.target) {
            this.$emit('dragleave', event);
          }
        },

        keydown(event) {
          // with meta or control key
          if (event.metaKey || event.ctrlKey) {
            switch (event.code) {
              case 'KeyS':
                event.preventDefault();
                this.$emit('key.save', event);
                return true;
              default:
                this.$emit(`key.cmd+${event.key}`, event);
                return true;
            }
          }

          if (typeof event.code === 'string') {
            this.$emit(`key.${lcfirst(event.code)}`, event);
          } else {
            this.$emit(`key.${event.code}`);
          }

          this.$emit('keydown', event);
          return true;
        },

        keyup(event) {
          this.$emit('keyup', event);
        },

        online(event) {
          this.$emit('online', event);
        },

        offline(event) {
          this.$emit('offline', event);
        },

        prevent(event) {
          event.stopPropagation();
          event.preventDefault();
        },
      },
    });
  },
};
