<template>
  <mui-topbar>
    <template slot="logo">
      <mui-logo
        title="Minerva"
        :tenant="title"
        :status="status"
      />

      <mui-button-group style="flex-wrap:nowrap">
        <mui-button
          theme="nav-inverse"
          icon="arrow-left"
          @click="$router.back()"
          v-if="isCrossRoute"
        />
      </mui-button-group>
    </template>

    <template slot="actions">
      <!-- <mui-dropdown>
        <mui-button
          theme="magic"
          @click="$refs.dropdownActions.toggle()"
          icon="plus"
          class="mr-4"
        />

        <mui-dropdown-content ref="dropdownActions" align="center">
          <mui-headline size="smallest" class="mui-dropdown-headline">
            Neu anlegen
          </mui-headline>

          <mui-button-group direction="vertical">
            <mui-button
              theme="nav"
              icon="phone-plus"
              @click="$refs.createReservation.dialog.open()"
            >
              Wartelisten-Eintrag
            </mui-button>
            <mui-button
              theme="nav"
              icon="calendar-plus"
              @click="$refs.createEvent.dialog.open()"
            >
              Termin
            </mui-button>
          </mui-button-group>
        </mui-dropdown-content>
      </mui-dropdown>

      <mui-dialog-create-reservation
        ref="createReservation"
        as-dialog
        @created-reservation="createdReservation"
      />
      <mui-dialog-create-event
        ref="createEvent"
        as-dialog
        @created-event="createdEvent"
      />-->

      <mui-app-search ref="search" />
    </template>

    <mui-button-group slot="nav" v-if="$mq.breakpoint === 'mobile'">
      <mui-button
        theme="nav-inverse"
        icon="bars"
        @click="$emit('on-sidebar-open')"
        v-if="!isSidebarVisible"
      />
      <mui-button
        theme="nav-inverse"
        icon="times"
        @click="$emit('on-sidebar-close')"
        v-else
      />
    </mui-button-group>

    <mui-button-group slot="nav" v-else>
      <mui-dropdown v-if="user">
        <mui-button
          theme="nav-inverse"
          icon="user-circle"
          @click="$refs.dropdownAccount.toggle()"
        >
          {{ user.name }}
        </mui-button>

        <mui-dropdown-content align="right" ref="dropdownAccount">
          <mui-headline size="smallest" class="mui-dropdown-headline">
            Eingeloggt als {{ user.name }}
          </mui-headline>

          <mui-button-group direction="vertical">
            <mui-button
              link="/account"
              theme="nav"
              icon="user-circle"
              @click.native="onDropdownButtonClick"
            >
              Konto
            </mui-button>
            <mui-button
              link="/logout"
              theme="nav"
              icon="sign-out"
              @click.native="onDropdownButtonClick"
            >
              Abmelden
            </mui-button>
          </mui-button-group>
          <hr v-if="can('users.index') && can('settings.index')">
          <mui-button-group direction="vertical" v-if="can('users.index') && can('settings.index')">
            <mui-button
              v-if="can('users.index')"
              link="/users"
              theme="nav"
              icon="user-secret"
              @click.native="onDropdownButtonClick"
            >
              Benutzer
            </mui-button>
            <mui-button
              v-if="can('settings.index')"
              link="/settings"
              theme="nav"
              icon="cog"
              @click.native="onDropdownButtonClick"
            >
              Einstellungen
            </mui-button>
          </mui-button-group>
        </mui-dropdown-content>
      </mui-dropdown>
    </mui-button-group>
  </mui-topbar>
</template>

<script>
import config from '@/config';

// import EventsCreate from '@/views/Dialogs/EventsCreate.vue';
// import ReservationsCreate from '@/views/Dialogs/ReservationsCreate.vue';

export default {
  // components: {
  //   'mui-dialog-create-event': EventsCreate,
  //   'mui-dialog-create-reservation': ReservationsCreate,
  // },

  props: {
    isSidebarVisible: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    title() {
      if (this.$mq.breakpoint !== 'mobile') {
        return config.app.title;
      }

      return null;
    },

    user() {
      return this.$store.state.auth.user;
    },

    status() {
      return this.$store.getters['system/status'];
    },

    isCrossRoute() {
      return this.$route.params.cross;
    },
  },

  methods: {
    can(permission) {
      return this.$store.getters['auth/isAllowedTo'](permission);
    },

    onDropdownButtonClick() {
      if (!this.$refs.dropdownAccount) return;
      this.$refs.dropdownAccount.close();
    },
  },
};
</script>
