<template>
  <mui-data-element
    :placeholder="!text"
    :label="label"
    v-bind="$props"
    v-on="$listeners"
  >
    <mui-strings-name :person="person" address v-if="person" />
    <template v-else>
      {{ placeholder }}
    </template>
  </mui-data-element>
</template>

<script>
export default {
  props: {
    person: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
};
</script>
