<template>
  <span class="-formatted-price">
    <span class="-currency">
      <mui-headline size="smallest">{{ currency }}</mui-headline>
    </span>
    <span class="-price">{{ priceFormatted }}
    </span>
  </span>
</template>

<script>
export default {
  props: {
    price: {
      type: [String, Number],
      default: null,
    },

    currency: {
      type: String,
      default: 'EUR',
    },
  },

  computed: {
    priceFormatted() {
      if (this.price) {
        return parseFloat(this.price).toLocaleString(undefined, { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
      }

      return '0,00';
    },
  },
};
</script>

<style>
.-formatted-price {
  display: flex;
  align-items: flex-end;
}

.-formatted-price .-currency {
  margin-right: .5rem;
}

.-formatted-price .-price {
  font-variant-numeric: tabular-nums;
}
</style>
