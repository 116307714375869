<template>
  <mui-data-element
    :placeholder="!computedText"
    v-bind="$props"
    v-on="$listeners"
  >
    <span v-if="computedText && multiline" v-html="nl2br(computedText)" />
    <span v-else-if="computedText">{{ computedText }}</span>
    <span v-else>{{ placeholder }}</span>
  </mui-data-element>
</template>

<script>
import { nl2br } from '@/helpers/strings';

export default {
  props: {
    text: {
      type: [String, Number],
      default: null,
    },

    label: {
      type: String,
      default: null,
    },

    icon: {
      type: String,
      default: null,
    },

    multiline: {
      type: Boolean,
      default: false,
    },

    placeholder: {
      type: String,
      default: '',
    },

    prefix: {
      type: String,
      default: null,
    },

    suffix: {
      type: String,
      default: null,
    },
  },

  computed: {
    computedText() {
      if (!this.text) {
        return null;
      }

      let { text } = this;

      if (this.prefix) {
        text = `${this.prefix} ${text}`;
      }
      if (this.suffix) {
        text = `${text} ${this.suffix}`;
      }

      return text;
    },
  },

  methods: {
    nl2br,
  },
};
</script>
