<template>
  <mui-field
    :label="label"
    :icon="icon"
    :info="info"
    :has-error="hasError"
    :has-warning="hasWarning"
    :id="id"
    :required="required"
    :edit="edit"
    :editable="editable"
    :readable="readable"
    :pinnable="pinnable"
    :removable="removable"
    @save="onSave"
    @pin="onPin"
    @remove="onRemove"
  >
    <textarea
      class="mui-input-element mui-input-element-textarea"
      :autofocus="autofocus"
      :required="required"
      @focus="resizeTextarea"
      :id="id"
      :placeholder="placeholder"
      v-bind="$attrs"
      v-on="inputListeners"
    />

    <mui-data-element-text
      multiline
      :icon="icon"
      :text="value"
      :placeholder="placeholder"
      slot="read"
    />
  </mui-field>
</template>

<script>
import field from '@/mixins/Field';

export default {
  mixins: [field],

  methods: {
    resizeTextarea(event) {
      const { target } = event;
      target.style.height = 'auto';
      target.style.height = `${target.scrollHeight + 2}px`; // border-size
    },
  },

  mounted() {
    this.$el.addEventListener('input', this.resizeTextarea);
  },

  beforeDestroy() {
    this.$el.removeEventListener('input', this.resizeTextarea);
  },
};
</script>
