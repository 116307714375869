const TOAST_LIFETIME = 5; // in seconds

export default {
  namespaced: true,

  state: {
    items: [],
  },

  mutations: {
    update(state, items) {
      state.items = items;
    },

    addToast(state, toast) {
      state.items.push(toast);
    },

    remove(state, id) {
      const item = state.items.find((toast) => toast.id === id);

      if (item) {
        const index = state.items.indexOf(item);
        state.items.splice(index, 1);
      }
    },
  },

  actions: {
    push({ commit, state, dispatch }, message) {
      const toast = {
        ...message,
      };

      if (!toast.id) {
        /** found on https://gist.github.com/gordonbrander/2230317 */
        toast.id = `_${Math.random().toString(36).substr(2, 9)}`;
      }

      if (state.items.find((item) => item.id === toast.id)) {
        return;
      }

      if (toast.type) {
        if (toast.type === 'error') {
          toast.icon = 'exclamation-triangle';
          toast.iconColor = 'orange-dark';
        }
      }

      commit('addToast', toast);

      if (!message.prevent) {
        const lifetime = message.lifetime ?? TOAST_LIFETIME;

        setTimeout(() => {
          dispatch('close', toast.id);
        }, lifetime * 1000);
      }
    },

    close({ commit }, id) {
      commit('remove', id);
    },
  },
};
