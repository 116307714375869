<template>
  <div id="app"
       :class="{
         'mui-app': true,
         '-inside': inside,
         '-outside': !inside
       }"
  >
    <mui-app-topbar
      v-if="inside"
      :is-sidebar-visible="isSidebarVisible"
      @on-sidebar-open="openSidebar"
      @on-sidebar-close="closeSidebar"
    />
    <mui-topbar v-else theme="light">
      <mui-logo slot="logo" title="Minerva" :tenant="appTitle" />

      <!-- <mui-button-group slot="nav">
        <mui-button icon="question-circle" />
      </mui-button-group> -->
    </mui-topbar>

    <mui-app-sidebar
      v-if="inside"
      :is-sidebar-visible="isSidebarVisible"
    />

    <mui-content>
      <router-view :key="routeKey()" />
    </mui-content>

    <mui-toasts
      :has-many="toasts.length > 0"
      :inside="inside"
    >
      <transition-group name="toast" tag="div">
        <mui-toast
          v-for="toast in toasts"
          :key="toast.id"
          @close="closeToast(toast.id)"
          :title="toast.title"
          :icon="toast.icon"
          :icon-color="toast.iconColor"
        >
          {{ toast.text }}
        </mui-toast>
      </transition-group>
    </mui-toasts>

    <mui-portal />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import config from '@/config';

export default {
  data() {
    return {
      isSidebarVisible: false,
    };
  },

  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'isStillValid']),

    toasts() {
      return this.$store.state.toasts.items;
    },

    appTitle() {
      return config.app.title;
    },

    inside() {
      return !this.$route.meta.outside && this.isLoggedIn();
    },
  },

  methods: {
    routeKey() {
      if (this.$route.name === 'calendar') {
        return 'calendar';
      }

      const path = this.$route.fullPath.split('/');
      if (path.length > 3) {
        path.pop();
      }
      return path.join(':');
    },

    openSidebar() {
      this.isSidebarVisible = true;

      this.$router.beforeEach((to, from, next) => {
        this.isSidebarVisible = false;
        next();
      });
    },

    closeSidebar() {
      this.isSidebarVisible = false;
    },

    closeToast(id) {
      this.$store.dispatch('toasts/close', id);
    },
  },

  mounted() {
    if (process.env.NODE_ENV === 'production') {
      this.$store.dispatch('system/compareVersion');
    }

    if (this.inside) {
      this.$store.dispatch('system/loadData');
    }
  },
};
</script>

<style lang="scss">
body {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

.mui-app {
  &.-outside {
    padding: 0;
    display: block;
    height: 100vh;

    .mui-content {
      height: 100%;
    }
  }
}
</style>
