import config from '@/config';
import storage from '@/utils/storage';
import http from './http';
import { queryString } from './http-helper';

/**
 * Call with GET method
 *
 * @param {string} uri
 * @param {string} data
 */
export function get(uri, data = {}) {
  let uriWithQuery = uri;
  const query = queryString(data);

  if (query) {
    uriWithQuery += `?${query}`;
  }

  return http.get(uriWithQuery);
}

/**
 * Call with POST method
 *
 * @param {string} uri
 * @param {string} data
 */
export function post(uri, data = {}) {
  return http.post(uri, data);
}

/**
 * Call with PUT method
 *
 * @param {string} uri
 * @param {string} data
 */
export function put(uri, data) {
  return http.put(uri, data);
}

/**
 * Call with PATCH method
 *
 * @param {string} uri
 * @param {string} data
 */
export function patch(uri, data) {
  return http.patch(uri, data);
}

/**
 * Call with DELETE method
 *
 * @param {string} uri
 */
export function destroy(uri) {
  return http.delete(uri);
}

/**
 * Do an upload via fetch
 *
 * @param {string} uri
 * @param {string} data
 */
export function upload(uri, data) {
  const storedData = storage.get(config.storage.ACCESS_TOKEN);

  const Headers = {
    Accept: 'application/json',
    Authorization: `Bearer ${storedData.access_token}`,
  };

  return fetch(config.api.endpoint + uri, {
    headers: Headers,
    method: 'POST',
    body: data,
  });
}
