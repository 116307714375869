<template>
  <mui-shield
    icon="strikethrough"
    color="red"
    :text="withText ? 'Storniert' : null"
    v-if="invoice.cancelled_at !== null"
  />
  <mui-shield
    icon="check"
    color="green"
    :text="withText ? 'Gesendet' : null"
    v-else-if="invoice.status === 'generated' && invoice.sent_at !== null"
  />
  <mui-shield
    icon="share"
    color="yellow"
    :text="withText ? 'Zum Versand bereit' : null"
    v-else-if="invoice.status === 'generated'"
  />
  <mui-shield
    icon="circle"
    color="blue"
    :text="withText ? 'Abgeschlossen' : null"
    v-else-if="invoice.status === 'finished'"
  />
  <mui-shield
    icon="pen"
    :text="withText ? 'Entwurf' : null"
    v-else-if="invoice.status === 'draft'"
  />
</template>

<script>
import datefns from '@/helpers/datefns';

export default {
  props: {
    invoice: {
      type: Object,
      required: true,
    },

    withText: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    toDateString: datefns.toDateString,
  },
};
</script>
