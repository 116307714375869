import axios from 'axios';
import { get } from '@/utils/api';
import storage from '@/utils/storage';
import config from '@/config';

const LOADING_INDICATOR_LIFE = 3; // in seconds

export default {
  namespaced: true,

  state: {
    isLoading: null,

    countries: [],
    cities: [],
    tags: [],

    navigation: null,
    navigationLoading: false,
    navigationInterval: null,
  },

  mutations: {
    startLoading(state) {
      state.isLoading = true;
    },

    finishLoading(state, status) {
      state.isLoading = status;
    },

    resetLoading(state) {
      state.isLoading = null;
    },

    setCountries(state, countries) {
      state.countries = countries;
    },

    setCities(state, cities) {
      state.cities = cities;
    },

    setTags(state, tags) {
      state.tags = tags;
    },

    setNavigation(state, navigation) {
      state.navigation = navigation;
    },

    setNavigationLoading(state, navigationLoading) {
      state.navigationLoading = navigationLoading;
    },

    setNavigationInterval(state, interval) {
      state.navigationInterval = interval;
    },
  },

  actions: {
    startLoading({ commit }) {
      commit('startLoading');
    },

    finishLoading({ commit }, status) {
      if (status) {
        commit('finishLoading', status);
        setTimeout(() => {
          commit('resetLoading');
        }, LOADING_INDICATOR_LIFE * 1000);
      } else {
        commit('finishLoading', null);
      }
    },

    resetLoading({ commit }) {
      commit('resetLoading');
    },

    setCities({ commit }, cities) {
      commit('setCities', cities);
    },

    setTags({ commit }, tags) {
      commit('setTags', tags);
    },

    setCountries({ commit }, countries) {
      commit('setCountries', countries);
    },

    setNavigation({ commit }, navigation) {
      commit('setNavigation', navigation);
    },

    async compareVersion({ dispatch }) {
      const currentVersion = storage.get(config.storage.VERSION) || null;

      const versionKey = document.getElementById('script').dataset.key;

      const newVersionResponse = await axios.get('/mix-manifest.json');
      const newVersionEntry = Object.entries(newVersionResponse.data).find((obj) => obj[0] === versionKey);

      if (currentVersion === null) {
        dispatch('loadNewVersion', newVersionEntry[1]);
      } else if (currentVersion !== newVersionEntry[1]) {
        // eslint-disable-next-line no-alert
        const loadNewVersion = window.confirm(config.messages.NEW_VERSION);

        if (loadNewVersion) {
          dispatch('loadNewVersion', newVersionEntry[1]);
        }
      }
    },

    loadNewVersion({ dispatch }, newVersion) {
      storage.set(config.storage.VERSION, newVersion);
      window.location.reload(true);
    },

    async loadData({ commit, dispatch }) {
      dispatch('startLoading');

      const citiesResponse = await get('/cities');
      if (citiesResponse.data) {
        commit('setCities', citiesResponse.data.data);
      }

      const countriesResponse = await get('/countries');
      if (countriesResponse.data) {
        commit('setCountries', countriesResponse.data.data);
      }

      const tagsResponse = await get('/tags', { type: 'system' });
      if (tagsResponse.data) {
        commit('setTags', tagsResponse.data.data);
      }

      dispatch('finishLoading', 'success');
    },

    loadNavigation({ commit, state }) {
      const navigationInterval = async () => {
        if (state.navigationLoading) return;

        commit('setNavigationLoading', true);

        const response = await get('/navigation');
        if (response) {
          commit('setNavigation', response.data);
        }

        commit('setNavigationLoading', false);
      };

      navigationInterval();

      commit('setNavigationInterval', setInterval(
        navigationInterval,
        5000,
      ));
    },

    resetLocalViews() {
      const storageItems = [];
      const keys = Object.keys(localStorage);
      let i = keys.length;

      // eslint-disable-next-line no-plusplus
      while (i--) {
        storageItems.push(keys[i]);
      }

      storageItems.forEach((item) => {
        if (item !== config.storage.ACCESS_TOKEN) {
          localStorage.removeItem(item);
        }
      });
    },
  },

  getters: {
    isSuccess: (state) => () => state.isLoading === 'success',
    isError: (state) => () => state.isLoading === 'error',
    isLoading: (state, getters) => () => !!state.isLoading && !getters.isSuccess() && !getters.isError(),
    status: (state, getters) => {
      if (getters.isLoading()) return 'loading';
      return state.isLoading;
    },

    getCity: (state) => (postcode) => state.cities.find((city) => city.postcode === postcode),
    getCountry: (state) => (id) => state.cities.find((country) => country.id === id),
  },
};
