<template>
  <mui-sheet>
    <mui-toolbar slot="header">
      <mui-button-group slot="left">
        <mui-button icon="birthday-cake" theme="ghost-highlight-static">
          Geburtstage
        </mui-button>
      </mui-button-group>
    </mui-toolbar>

    <mui-loader v-if="isLoading" />
    <template v-else>
      <mui-sheet-inner padded="top" class="pb-4">
        <mui-headline size="medium">
          <strong>{{ todayLabel }}</strong>
        </mui-headline>
      </mui-sheet-inner>
      <mui-sheet-inner v-if="!birthdaysToday.length">
        Heute sind keine Geburtstage eingetragen.
      </mui-sheet-inner>
      <div class="p-2" v-else>
        <mui-tile-person
          v-for="person in birthdaysToday"
          :key="person.id"
          :person="person"
          :type="person.type"
          :subline="`${person.age} Jahre`"
        />
      </div>

      <mui-sheet-inner padded="top" class="pb-4">
        <mui-headline size="medium">
          <strong>{{ tomorrowLabel }}</strong>
        </mui-headline>
      </mui-sheet-inner>
      <mui-sheet-inner v-if="!birthdaysTomorrow.length" padded="bottom">
        Morgen sind keine Geburtstage eingetragen.
      </mui-sheet-inner>
      <div class="p-2" v-else>
        <mui-tile-person
          v-for="person in birthdaysTomorrow"
          :key="person.id"
          :person="person"
          :type="person.type"
          :subline="`${person.age} Jahre`"
        />
      </div>
    </template>
  </mui-sheet>
</template>

<script>
import { get } from '@/utils/api';
import {
  add,
} from 'date-fns';
import datefns from '@/helpers/datefns';

export default {
  data() {
    return {
      isLoading: true,
      birthdaysToday: null,
      birthdaysTomorrow: null,
    };
  },

  computed: {
    today() {
      return new Date();
    },

    tomorrow() {
      return add(this.today, { days: 1 });
    },

    todayLabel() {
      return datefns.toTitleString(this.today);
    },

    tomorrowLabel() {
      return datefns.toTitleString(this.tomorrow);
    },
  },

  methods: {
    async loadBirthdays() {
      this.birthdaysToday = await this.loadBirthdaysFor(datefns.toSqlDate(this.today));
      this.birthdaysTomorrow = await this.loadBirthdaysFor(datefns.toSqlDate(this.tomorrow));
      this.isLoading = false;
    },

    async loadBirthdaysFor(date = true) {
      const responseForEmployees = await get('/employees', {
        filter: {
          hasBirthday: date,
        },
        fields: {
          employees: 'id,birthday,firstname,name',
        },
        append: 'age,fullname',
      });

      const responseForCustomers = await get('/customers', {
        filter: {
          hasBirthday: date,
        },
        fields: {
          customers: 'id,birthday,firstname,name',
        },
        append: 'age,fullname',
      });

      const birthdaysOfEmployees = responseForEmployees.data.data.map((person) => ({
        ...person,
        type: 'employee',
      }));

      const birthdaysOfCustomers = responseForCustomers.data.data.map((person) => ({
        ...person,
        type: 'customer',
      }));

      return [
        ...birthdaysOfEmployees,
        ...birthdaysOfCustomers,
      ];
    },
  },

  mounted() {
    this.loadBirthdays();
  },
};
</script>
