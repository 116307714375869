import axios from 'axios';
import config from '@/config';
import storage from '@/utils/storage';

export const esc = encodeURIComponent;


const getQueryPart = (key, value) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;

export const queryString = (params) => {
  const query = [];

  Object.keys(params).forEach((paramKey) => {
    const paramItem = params[paramKey];

    if (!paramItem) return;

    if (typeof paramItem === 'object') {
      Object.keys(paramItem).forEach((paramNestedKey) => {
        const paramNestedKeyString = `${paramKey}[${paramNestedKey}]`;
        const paramNestedValue = paramItem[paramNestedKey];

        if (paramNestedValue) {
          const paramString = getQueryPart(paramNestedKeyString, paramNestedValue);
          query.push(paramString);
        }
      });
      return;
    }

    const paramString = getQueryPart(paramKey, paramItem);
    query.push(paramString);
  });

  return query.join('&');
};


export const getHeaders = (defaultHeaders = {}) => {
  const Headers = {
    Accept: 'application/json',
    // 'Content-Type': 'application/json',
    ...defaultHeaders,
    ...axios.defaults.headers.common,
  };

  const storedData = storage.get(config.storage.ACCESS_TOKEN);

  if (storedData) {
    Headers.Authorization = `Bearer ${storedData.access_token}`;
  }

  return Headers;
};
