<template>
  <mui-data-element
    :placeholder="!password"
    v-bind="$props"
    v-on="$listeners"
  >
    {{ password || placeholder }}
  </mui-data-element>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },

  computed: {
    password() {
      if (!this.text) return null;
      const { length } = this.text;
      return '•'.repeat(length);
    },
  },
};
</script>
