<template>
  <mui-data-element
    title="E-Mail schreiben"
    icon="envelope"
    :label="label"
    :placeholder="!email"
    :link="`mailto:${email}`"
    v-on="$listeners"
  >
    {{ email || placeholder }}
  </mui-data-element>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    email: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
};
</script>
